import React from "react";
import { Link } from "react-router-dom";

export const LandingPage = () => {
    return (
        <>
            <header className="fixed top-2 z-30 w-full md:top-6">
                <div className="mx-auto max-w-6xl px-4 sm:px-6">
                    <div className="relative flex h-14 items-center justify-between gap-3 rounded-2xl bg-white/90 px-3 shadow-lg shadow-black/[0.03] backdrop-blur-sm before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(theme(colors.gray.100),theme(colors.gray.200))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)]">
                        <div className="flex flex-1 items-center">
                            <a className="inline-flex" aria-label="Cruip" href="#">
                                <svg width="29" height="33" viewBox="0 0 29 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.17157 37.3066C3.4846 37.3066 1.83277 37.3066 0.0403702 37.3066C0.110661 32.808 1.97335 29.2935 5.62845 26.3413C5.32972 26.1656 5.06613 26.0777 4.8904 25.9196C0.637834 22.1239 -0.961267 17.3969 0.567548 11.9142C2.11394 6.36128 5.96232 3.11035 11.6383 2.16143C14.6783 1.65182 17.5954 2.21414 20.2664 3.79568C20.9693 4.21742 21.3735 4.11198 21.8831 3.54965C23.0077 2.31957 24.1851 1.15979 25.2922 0C26.5926 1.30037 27.7699 2.51288 29 3.74296C27.6469 5.0609 26.2411 6.43156 24.8001 7.83737C25.0813 8.34698 25.2922 8.73357 25.503 9.12017C30.2652 17.924 23.9742 28.9245 13.993 29.0299C8.91452 29.0826 5.43515 32.9135 5.29457 36.8497C5.277 36.9727 5.20671 37.1133 5.17157 37.3066ZM13.5537 7.22233C8.98481 7.22233 5.24186 10.9653 5.25943 15.5166C5.29458 20.0679 8.96725 23.7933 13.4483 23.8284C18.0874 23.8636 21.8304 20.1558 21.8655 15.5166C21.8831 10.9829 18.1401 7.22233 13.5537 7.22233Z" fill="black" className="fill-black-500" fillRule="evenodd" />
                                    <path d="M27.0494 37.5175C27.0143 46.2686 19.1066 51.3295 13.624 50.7672C13.624 49.1329 13.624 47.4811 13.624 45.7766C18.5092 45.2143 21.3032 42.5081 21.9358 37.5175C23.6228 37.5175 25.2922 37.5175 27.0494 37.5175Z" fill="black" className="fill-black-500" fillRule="evenodd" />
                                    <path d="M15.0825 38.0798C15.0825 38.6773 14.608 39.1518 14.0106 39.1518C13.6767 39.1518 13.378 38.9936 13.1847 38.7652L10.4961 40.3994C10.4609 40.417 10.4082 40.417 10.3906 40.3818L10.1973 40.0655C10.1798 40.0304 10.1798 39.9777 10.2149 39.9601L12.9387 38.2907C12.9211 38.2204 12.9211 38.1501 12.9211 38.0798C12.9211 37.5878 13.255 37.1836 13.7118 37.0606V32.316C13.7118 32.2809 13.747 32.2457 13.7821 32.2457H14.1512C14.1863 32.2457 14.2215 32.2809 14.2215 32.316V37.043C14.7135 37.1485 15.0825 37.5702 15.0825 38.0798Z" fill="black" className="fill-black-500" fillRule="evenodd" />
                                </svg>

                            </a>
                        </div>
                        <ul className="flex flex-1 items-center justify-end gap-3 mt-20" style={{ marginTop: '13px', textDecoration: 'none', textTransform: 'none', }}>
                            <li>
                                <a
                                    className="btn-sm bg-white text-gray-800 shadow hover:bg-gray-50"
                                    href="/signin"
                                >
                                    Login
                                </a>
                            </li>
                            <li>
                                <Link
                                    className="btn-sm bg-gray-800 text-gray-200 shadow hover:bg-gray-900"
                                    to="/signup"
                                >
                                    Register
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
            <main className="grow">
                <section className="relative">
                    <div
                        className="pointer-events-none absolute left-1/2 top-0 -z-10 -translate-x-1/2 transform"
                        aria-hidden="true"
                    >
                        <img
                            alt="Stripes"
                            fetchpriority="high"
                            width="768"
                            height="438"
                            decoding="async"
                            data-nimg="1"
                            className="max-w-none"
                            style={{ color: "transparent" }}
                            src="images/media-stripes.39fe7903.svg"
                        />
                    </div>
                    <div
                        className="pointer-events-none absolute -top-32 left-1/2 ml-[580px] -translate-x-1/2"
                        aria-hidden="true"
                    >
                        <div className="h-80 w-80 rounded-full bg-gradient-to-tr from-blue-500 opacity-50 blur-[160px]"></div>
                    </div>
                    <div
                        className="pointer-events-none absolute left-1/2 top-[420px] ml-[380px] -translate-x-1/2"
                        aria-hidden="true"
                    >
                        <div className="h-80 w-80 rounded-full bg-gradient-to-tr from-blue-500 to-gray-900 opacity-50 blur-[160px]"></div>
                    </div>
                    <div
                        className="pointer-events-none absolute left-1/2 top-[640px] -ml-[300px] -translate-x-1/2"
                        aria-hidden="true"
                    >
                        <div className="h-80 w-80 rounded-full bg-gradient-to-tr from-blue-500 to-gray-900 opacity-50 blur-[160px]"></div>
                    </div>
                    <div className="mx-auto max-w-6xl px-4 sm:px-6">
                        <div className="pb-12 pt-32 md:pb-20 md:pt-40">
                            <div className="pb-12 text-center md:pb-16">
                                <div className="mb-6 border-y [border-image:linear-gradient(to_right,transparent,theme(colors.slate.300/.8),transparent)1]">
                                    <div class="-mx-0.5 flex justify-center -space-x-3">

                                        <img
                                            alt="Avatar 01"
                                            loading="lazy"
                                            className="box-content rounded-full border-2 border-gray-50 w-8 h-8 md:w-16 md:h-16"
                                            style={{ color: "transparent" }}
                                            src="images/avatar-01.jpg"
                                        /><img
                                            alt="Avatar 02"
                                            loading="lazy"
                                            className="box-content rounded-full border-2 border-gray-50 w-8 h-8 md:w-16 md:h-16"
                                            style={{ color: "transparent" }}
                                            src="images/avatar-02.jpg"
                                        /><img
                                            alt="Avatar 03"
                                            loading="lazy"
                                            className="box-content rounded-full border-2 border-gray-50 w-8 h-8 md:w-16 md:h-16"
                                            style={{ color: "transparent" }}
                                            src="images/avatar-03.jpg"
                                        /><img
                                            alt="Avatar 04"
                                            loading="lazy"
                                            className="box-content rounded-full border-2 border-gray-50 w-8 h-8 md:w-16 md:h-16"
                                            style={{ color: "transparent" }}
                                            src="images/avatar-04.jpg"
                                        /><img
                                            alt="Avatar 05"
                                            loading="lazy"
                                            className="box-content rounded-full border-2 border-gray-50 w-8 h-8 md:w-16 md:h-16"
                                            style={{ color: "transparent" }}
                                            src="images/avatar-05.jpg"
                                        />
                                        <img
                                            alt="Avatar 06"
                                            loading="lazy"
                                            className="box-content rounded-full border-2 border-gray-50 w-8 h-8 md:w-16 md:h-16"
                                            style={{ color: "transparent" }}
                                            src="images/avatar-06.jpg"
                                        />
                                    </div>
                                </div>
                                <h1
                                    className="mb-6 border-y text-5xl font-bold [border-image:linear-gradient(to_right,transparent,theme(colors.slate.300/.8),transparent)1] md:text-6xl"
                                    data-aos-delay="150"
                                >
                                    Effortless Scheduling for Tattoo Studios
                                </h1>
                                <div className="mx-auto max-w-3xl">
                                    <p
                                        className="mb-8 text-lg text-gray-700"
                                        data-aos-delay="300"
                                    >
                                        Simplify bookings and streamline operations for your team and clients with ease.
                                    </p>
                                    <div className="relative before:absolute before:inset-0 before:border-y before:[border-image:linear-gradient(to_right,transparent,theme(colors.slate.300/.8),transparent)1]">
                                        <div
                                            className="mx-auto max-w-xs sm:flex sm:max-w-none sm:justify-center"
                                            data-aos-delay="450"
                                        >
                                            <a
                                                className="btn group mb-4 w-full bg-gradient-to-t from-blue-600 to-blue-500 bg-[length:100%_100%] bg-[bottom] text-white shadow hover:bg-[length:100%_150%] sm:mb-0 sm:w-auto"
                                                href="/signup"
                                                style={{ background: 'black' }}

                                            >
                                                <span className="relative inline-flex items-center">
                                                    Start Your Free Trial
                                                    <span className="ml-1 tracking-normal text-blue-300 transition-transform group-hover:translate-x-0.5">
                                                        {/* -&gt; */}
                                                    </span>
                                                </span>
                                            </a>
                                            {/* <a
                                                className="btn w-full bg-white text-gray-800 shadow hover:bg-gray-50 sm:ml-4 sm:w-auto"
                                                href="#0"
                                            >
                                                Learn More
                                            </a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mx-auto max-w-3xl" data-aos-delay="600">
                                <div className="relative aspect-video rounded-2xl bg-gray-900 px-5 py-3 shadow-xl before:pointer-events-none before:absolute before:-inset-5 before:border-y before:[border-image:linear-gradient(to_right,transparent,theme(colors.slate.300/.8),transparent)1] after:absolute after:-inset-5 after:-z-10 after:border-x after:[border-image:linear-gradient(to_bottom,transparent,theme(colors.slate.300/.8),transparent)1]">
                                    {/* <div className="relative mb-8 flex items-center justify-between before:block before:h-[9px] before:w-[41px] before:bg-[length:16px_9px] before:[background-image:radial-gradient(circle_at_4.5px_4.5px,_theme(colors.gray.600)_4.5px,_transparent_0)] after:w-[41px]">
                                        <span className="text-[13px] font-medium text-white">
                                            Inking.com
                                        </span>
                                    </div> */}
                                    {/* <div className="font-mono text-gray-500 [&amp;_span]:opacity-0">
                                        <span className="animate-[code-1_10s_infinite] text-gray-200">
                                            npm login
                                        </span>
                                        <span className="animate-[code-2_10s_infinite]">
                                            --registry=https://npm.pkg.github.com
                                        </span>
                                        <br />
                                        <span className="animate-[code-3_10s_infinite]">
                                            --scope=@phanatic
                                        </span>
                                        <span className="animate-[code-4_10s_infinite]">
                                            Successfully logged-in.
                                        </span>
                                        <br />
                                        <br />
                                        <span className="animate-[code-5_10s_infinite] text-gray-200">
                                            npm publish
                                        </span>
                                        <br />
                                        <span className="animate-[code-6_10s_infinite]">
                                            Package published.
                                        </span>
                                    </div> */}
                                    <iframe
                                        className="w-full h-full rounded-2xl"
                                        src="https://www.youtube.com/embed/P3aKRdUyr0s"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section>
                    <div className="mx-auto max-w-6xl px-4 sm:px-6">
                        <div className="pb-12 md:pb-20">
                            <div className="relative flex h-[324px] items-center justify-center">
                                <div className="absolute -z-10">
                                    <svg
                                        className="fill-black-500"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="164"
                                        height="41"
                                        viewBox="0 0 164 41"
                                        fill="none"
                                    >
                                        <circle cx="1" cy="8" r="1" fillOpacity="0.24"></circle>
                                        <circle cx="1" cy="1" r="1" fillOpacity="0.16"></circle>
                                        <circle cx="1" cy="15" r="1"></circle>
                                        <circle cx="1" cy="26" r="1" fillOpacity="0.64"></circle>
                                        <circle cx="1" cy="33" r="1" fillOpacity="0.24"></circle>
                                        <circle cx="8" cy="8" r="1"></circle>
                                        <circle cx="8" cy="15" r="1"></circle>
                                        <circle cx="8" cy="26" r="1" fillOpacity="0.24"></circle>
                                        <circle cx="15" cy="15" r="1" fillOpacity="0.64"></circle>
                                        <circle cx="15" cy="26" r="1" fillOpacity="0.16"></circle>
                                        <circle cx="8" cy="33" r="1"></circle>
                                        <circle cx="1" cy="40" r="1"></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 164 7)"
                                            fillOpacity="0.24"
                                        ></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 164 0)"
                                            fillOpacity="0.16"
                                        ></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 164 14)"
                                        ></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 164 25)"
                                            fillOpacity="0.64"
                                        ></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 164 32)"
                                            fillOpacity="0.24"
                                        ></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 157 7)"
                                        ></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 157 14)"
                                        ></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 157 25)"
                                            fillOpacity="0.24"
                                        ></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 150 14)"
                                            fillOpacity="0.64"
                                        ></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 150 25)"
                                            fillOpacity="0.16"
                                        ></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 157 32)"
                                        ></circle>
                                        <circle
                                            cx="1"
                                            cy="1"
                                            r="1"
                                            transform="matrix(-1 0 0 1 164 39)"
                                        ></circle>
                                    </svg>
                                </div>
                                <div className="absolute -z-10">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="432"
                                        height="160"
                                        viewBox="0 0 432 160"
                                        fill="none"
                                    >
                                        <g opacity="0.6" filter="url(#filter0_f_2044_9)">
                                            <path
                                                className="fill-black-500"
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M80 112C62.3269 112 48 97.6731 48 80C48 62.3269 62.3269 48 80 48C97.6731 48 171 62.3269 171 80C171 97.6731 97.6731 112 80 112ZM352 112C369.673 112 384 97.6731 384 80C384 62.3269 369.673 48 352 48C334.327 48 261 62.3269 261 80C261 97.6731 334.327 112 352 112Z"
                                            ></path>
                                        </g>
                                        <defs>
                                            <filter
                                                id="filter0_f_2044_9"
                                                x="0"
                                                y="0"
                                                width="432"
                                                height="160"
                                                filterUnits="userSpaceOnUse"
                                                colorInterpolationFilters="sRGB"
                                            >
                                                <feflood
                                                    floodOpacity="0"
                                                    result="BackgroundImageFix"
                                                ></feflood>
                                                <feblend
                                                    mode="normal"
                                                    in="SourceGraphic"
                                                    in2="BackgroundImageFix"
                                                    result="shape"
                                                ></feblend>
                                                <fegaussianblur
                                                    stdDeviation="32"
                                                    result="effect1_foregroundBlur_2044_9"
                                                ></fegaussianblur>
                                            </filter>
                                        </defs>
                                    </svg>
                                </div>
                                <div className="absolute inset-x-0 top-0 -z-10 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply"></div>
                                <div className="absolute inset-x-0 bottom-0 -z-10 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply"></div>
                                <div className="absolute inset-x-[200px] top-1/2 -z-10 h-px bg-gradient-to-r from-transparent via-blue-500/60 to-transparent mix-blend-multiply"></div>
                                <div className="absolute inset-x-0 top-1/2 -z-10 h-px -translate-y-[82px] bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply before:absolute before:inset-y-0 before:w-24 before:animate-[line_10s_ease-in-out_infinite_both] before:bg-gradient-to-r before:via-blue-500"></div>
                                <div className="absolute inset-x-0 top-1/2 -z-10 h-px translate-y-[82px] bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply before:absolute before:inset-y-0 before:w-24 before:animate-[line_10s_ease-in-out_infinite_5s_both] before:bg-gradient-to-r before:via-blue-500"></div>
                                <div className="absolute inset-x-[300px] top-1/2 -z-10 h-px rotate-[20deg] bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply"></div>
                                <div className="absolute inset-x-[300px] top-1/2 -z-10 h-px -rotate-[20deg] bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply"></div>
                                <div className="absolute inset-y-0 left-1/2 -z-10 w-px -translate-x-[216px] bg-gradient-to-b from-gray-200 to-transparent mix-blend-multiply"></div>
                                <div className="absolute inset-y-0 left-1/2 -z-10 w-px translate-x-[216px] bg-gradient-to-t from-gray-200 to-transparent mix-blend-multiply"></div>
                                <div className="absolute before:absolute before:-inset-3 before:animate-[spin_3s_linear_infinite] before:rounded-full before:border before:border-transparent before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)] before:[background:conic-gradient(from_180deg,transparent,theme(colors.blue.500))_border-box]">
                                    <div className="animate-[breath_8s_ease-in-out_infinite_both]">
                                        <div className="flex h-24 w-24 items-center justify-center rounded-full bg-white shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:rounded-[inherit] before:border before:border-gray-700/5 before:bg-gray-200/60 before:[mask-image:linear-gradient(to_bottom,black,transparent)]">
                                            <img
                                                alt="Logo 01"
                                                loading="lazy"
                                                width="32"
                                                height="32"
                                                decoding="async"
                                                data-nimg="1"
                                                className="relative"
                                                style={{ color: "transparent" }}
                                                src="images/media-logo-01.84f0bef6.svg"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="relative flex flex-col">
                                    <article className="flex h-full w-full items-center justify-center focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300">
                                        <div className="absolute -translate-x-[136px]">
                                            <div className="animate-[breath_7s_ease-in-out_3s_infinite_both]">
                                                <div className="flex h-16 w-16 items-center justify-center rounded-full bg-white shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:rounded-[inherit] before:border before:border-gray-700/5 before:bg-gray-200/60 before:[mask-image:linear-gradient(to_bottom,black,transparent)]">
                                                    <img
                                                        alt="Logo 02"
                                                        loading="lazy"
                                                        width="23"
                                                        height="22"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="relative"
                                                        style={{ color: "transparent" }}
                                                        src="images/media-logo-02.c53c3b66.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute translate-x-[136px]">
                                            <div className="animate-[breath_7s_ease-in-out_3.5s_infinite_both]">
                                                <div className="flex h-16 w-16 items-center justify-center rounded-full bg-white shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:rounded-[inherit] before:border before:border-gray-700/5 before:bg-gray-200/60 before:[mask-image:linear-gradient(to_bottom,black,transparent)]">
                                                    <img
                                                        alt="Logo 03"
                                                        loading="lazy"
                                                        width="22"
                                                        height="22"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="relative"
                                                        style={{ color: "transparent" }}
                                                        src="images/media-logo-03.3b762385.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute -translate-x-[216px] -translate-y-[82px]">
                                            <div className="animate-[breath_6s_ease-in-out_3.5s_infinite_both]">
                                                <div className="flex h-20 w-20 items-center justify-center rounded-full bg-white shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:rounded-[inherit] before:border before:border-gray-700/5 before:bg-gray-200/60 before:[mask-image:linear-gradient(to_bottom,black,transparent)]">
                                                    <img
                                                        alt="Logo 04"
                                                        loading="lazy"
                                                        width="24"
                                                        height="22"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="relative"
                                                        style={{ color: "transparent" }}
                                                        src="./images/media-logo-04.5487b301.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute -translate-y-[82px] translate-x-[216px]">
                                            <div className="animate-[breath_6s_ease-in-out_1.5s_infinite_both]">
                                                <div className="flex h-20 w-20 items-center justify-center rounded-full bg-white shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:rounded-[inherit] before:border before:border-gray-700/5 before:bg-gray-200/60 before:[mask-image:linear-gradient(to_bottom,black,transparent)]">
                                                    <img
                                                        alt="Logo 05"
                                                        loading="lazy"
                                                        width="25"
                                                        height="25"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="relative"
                                                        style={{ color: "transparent" }}
                                                        src="images/media-logo-05.6c7db8f6.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute translate-x-[216px] translate-y-[82px]">
                                            <div className="animate-[breath_6s_ease-in-out_2s_infinite_both]">
                                                <div className="flex h-20 w-20 items-center justify-center rounded-full bg-white shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:rounded-[inherit] before:border before:border-gray-700/5 before:bg-gray-200/60 before:[mask-image:linear-gradient(to_bottom,black,transparent)]">
                                                    <img
                                                        alt="Logo 06"
                                                        loading="lazy"
                                                        width="20"
                                                        height="18"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="relative"
                                                        style={{ color: "transparent" }}
                                                        src="images/media-logo-06.c9627b6e.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute -translate-x-[216px] translate-y-[82px]">
                                            <div className="animate-[breath_6s_ease-in-out_2.5s_infinite_both]">
                                                <div className="flex h-20 w-20 items-center justify-center rounded-full bg-white shadow-lg shadow-black/[0.03] before:absolute before:inset-0 before:m-[8.334%] before:rounded-[inherit] before:border before:border-gray-700/5 before:bg-gray-200/60 before:[mask-image:linear-gradient(to_bottom,black,transparent)]">
                                                    <img
                                                        alt="Logo 07"
                                                        loading="lazy"
                                                        width="25"
                                                        height="25"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="relative"
                                                        style={{ color: "transparent" }}
                                                        src="images/media-logo-07.e50f4152.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute -translate-x-[292px] opacity-40">
                                            <div className="animate-[breath_6s_ease-in-out_2s_infinite_both]">
                                                <div className="flex h-12 w-12 items-center justify-center rounded-full border border-gray-200/60 bg-white shadow-lg">
                                                    <img
                                                        alt="Logo 08"
                                                        loading="lazy"
                                                        width="20"
                                                        height="20"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="relative"
                                                        style={{ color: "transparent" }}
                                                        src="images/media-logo-08.121dd6fc.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="absolute translate-x-[292px] opacity-40">
                                            <div className="animate-[breath_6s_ease-in-out_4s_infinite_both]">
                                                <div className="flex h-12 w-12 items-center justify-center rounded-full border border-gray-200/60 bg-white shadow-lg">
                                                    <img
                                                        alt="Logo 09"
                                                        loading="lazy"
                                                        width="21"
                                                        height="13"
                                                        decoding="async"
                                                        data-nimg="1"
                                                        className="relative"
                                                        style={{ color: "transparent" }}
                                                        src="images/media-logo-09.177906bf.svg"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="relative before:absolute before:inset-0 before:-z-20 before:bg-gray-900" style={{ background: 'black' }}>
                    <div className="mx-auto max-w-6xl px-4 sm:px-6">
                        <div className="py-12 md:py-20">
                            <div className="mx-auto max-w-3xl pb-16 text-center md:pb-20">
                                <h2 className="text-3xl font-bold text-gray-200 md:text-4xl">
                                    Manage appointments, clients, and schedules effortlessly-no more double bookings or missed opportunities.
                                </h2>
                            </div>
                            <div className="pb-16 md:pb-20 mx-auto max-w-md custom-width"> {/* Centered and width decreased */}
                                <div className="c8a4e chip0 csakt cuk8q c4a1n cz5m3 aos-init aos-animate custom-border" data-aos="zoom-out" data-aos-delay="100">
                                    <div className="c307p c5n0y cqa37 c75iz cjdk8">
                                        <div className="cpfyo c4mnq czrr8 cdjvi c5mpl cld06 cimbm cdur3 cuzn3">
                                            <svg className="cjxzk c48lf" width="12" height="14" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.315.068a.5.5 0 0 0-.745.347A7.31 7.31 0 0 1 3.182 3.6a7.924 7.924 0 0 1-.8.83A6.081 6.081 0 0 0 0 9.035a5.642 5.642 0 0 0 2.865 4.9.5.5 0 0 0 .746-.4 2.267 2.267 0 0 1 .912-1.67 4.067 4.067 0 0 0 1.316-1.4 4.662 4.662 0 0 1 1.819 3.1.5.5 0 0 0 .742.371c1.767-.999 2.86-2.87 2.865-4.9-.001-3.589-2.058-6.688-5.95-8.968Z"></path>
                                            </svg>
                                            <span>Most Popular</span>
                                        </div>
                                    </div>
                                    <div className="ciljg">
                                        <div className="ch741 cblub cdur3">Standard Package
                                        </div>
                                        <div className="c6u0s cpfyo c6nex cejd3">
                                            <span className="ch05i cazq3 ckdyj">$</span>
                                            <span className="c6shg c4bs9 c06qx">39</span>
                                            <span className="cazq3 ckdyj">/month</span>
                                        </div>
                                        {/* <div className="ciljg ckdyj">For teams building apps for many public &amp; private users.</div> */}
                                        <div className="ciljg ckdyj">No hidden fees-just everything your studio needs. Start today with Inking!</div>

                                        <a className="c2pi2 c0ayg c4wey cl6ef cf4pm cqbpd cxmkl c4aul c76qn" href="/signup" style={{ background: "white", color: 'black' }}>
                                            Start Free Trial
                                        </a>
                                    </div>
                                    {/* <div className="cvx4v cazq3">No hidden fees-just everything your studio needs. Start today with Inking! */}
                                    {/* </div> */}
                                    <ul className="cxgjn ch3s0 ckdyj">
                                        <li className="chip0 c4mnq">
                                            <svg className="cejbf c8u3n cn0vf cveoo c6f9y cmb3e" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span>Unlimited Appointments</span>
                                        </li>
                                        <li className="chip0 c4mnq">
                                            <svg className="cejbf c8u3n cn0vf cveoo c6f9y cmb3e" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span>Unlimited Clients</span>
                                        </li>
                                        <li className="chip0 c4mnq">
                                            <svg className="cejbf c8u3n cn0vf cveoo c6f9y cmb3e" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span>Unlimited Employees</span>
                                        </li>
                                        <li className="chip0 c4mnq">
                                            <svg className="cejbf c8u3n cn0vf cveoo c6f9y cmb3e" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span>Client Management Tools</span>
                                        </li>
                                        <li className="chip0 c4mnq">
                                            <svg className="cejbf c8u3n cn0vf cveoo c6f9y cmb3e" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span>Employee Scheduling</span>
                                        </li>
                                        <li className="chip0 c4mnq">
                                            <svg className="cejbf c8u3n cn0vf cveoo c6f9y cmb3e" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span>Unlimited Studios</span>
                                        </li>
                                        <li className="chip0 c4mnq">
                                            <svg className="cejbf c8u3n cn0vf cveoo c6f9y cmb3e" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z"></path>
                                            </svg>
                                            <span>24/7 Support</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="grid overflow-hidden sm:grid-cols-2 lg:grid-cols-3 [&amp;&gt;*]:relative [&amp;&gt;*]:p-6 [&amp;&gt;*]:before:absolute [&amp;&gt;*]:before:bg-gray-800 [&amp;&gt;*]:before:[block-size:100vh] [&amp;&gt;*]:before:[inline-size:1px] [&amp;&gt;*]:before:[inset-block-start:0] [&amp;&gt;*]:before:[inset-inline-start:-1px] [&amp;&gt;*]:after:absolute [&amp;&gt;*]:after:bg-gray-800 [&amp;&gt;*]:after:[block-size:1px] [&amp;&gt;*]:after:[inline-size:100vw] [&amp;&gt;*]:after:[inset-block-start:-1px] [&amp;&gt;*]:after:[inset-inline-start:0] md:[&amp;&gt;*]:p-10">
                                <article>
                                    <h3 className="mb-2 flex items-center space-x-2 font-medium text-gray-200">
                                        <svg
                                            className="fill-blue-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                        >
                                            <path d="M2 4a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V4Zm2-4a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4V4a4 4 0 0 0-4-4H4Zm1 10a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H5Z" fill="white"></path>
                                        </svg>
                                        <span>Instant Scheduling </span>
                                    </h3>
                                    <p className="text-[15px] text-gray-400">
                                        Easily manage and update your tattoo appointments with
                                        real-time availability updates
                                    </p>
                                </article>
                                <article>
                                    <h3 className="mb-2 flex items-center space-x-2 font-medium text-gray-200">
                                        <svg
                                            className="fill-white-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                        >
                                            <path d="M14.29 2.614a1 1 0 0 0-1.58-1.228L6.407 9.492l-3.199-3.2a1 1 0 1 0-1.414 1.415l4 4a1 1 0 0 0 1.496-.093l7-9ZM1 14a1 1 0 1 0 0 2h14a1 1 0 1 0 0-2H1Z" fill="white" />
                                        </svg>
                                        <span>Client Management</span>
                                    </h3>
                                    <p className="text-[15px] text-gray-400">
                                        Keep track of client information, preferences, and past
                                        tattoos for personalized service.
                                    </p>
                                </article>


                                <article>
                                    <h3 className="mb-2 flex items-center space-x-2 font-medium text-gray-200">
                                        <svg
                                            className="fill-blue-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                        >
                                            <path d="M10.284.33a1 1 0 1 0-.574 1.917 6.049 6.049 0 0 1 2.417 1.395A1 1 0 0 0 13.5 2.188 8.034 8.034 0 0 0 10.284.33ZM6.288 2.248A1 1 0 0 0 5.718.33 8.036 8.036 0 0 0 2.5 2.187a1 1 0 0 0 1.372 1.455 6.036 6.036 0 0 1 2.415-1.395ZM1.42 5.401a1 1 0 0 1 .742 1.204 6.025 6.025 0 0 0 0 2.79 1 1 0 0 1-1.946.462 8.026 8.026 0 0 1 0-3.714A1 1 0 0 1 1.421 5.4Zm2.452 6.957A1 1 0 0 0 2.5 13.812a8.036 8.036 0 0 0 3.216 1.857 1 1 0 0 0 .574-1.916 6.044 6.044 0 0 1-2.417-1.395Zm9.668.04a1 1 0 0 1-.041 1.414 8.033 8.033 0 0 1-3.217 1.857 1 1 0 1 1-.571-1.917 6.035 6.035 0 0 0 2.415-1.395 1 1 0 0 1 1.414.042Zm2.242-6.255a1 1 0 1 0-1.946.462 6.03 6.03 0 0 1 0 2.79 1 1 0 1 0 1.946.462 8.022 8.022 0 0 0 0-3.714Z" fill="white"></path>
                                        </svg>
                                        <span>Boost Your Studio's Efficiency</span>
                                    </h3>
                                    <p className="text-[15px] text-gray-400">
                                        Inking saves you time on scheduling, letting your team concentrate on creating incredible tattoos and keeping your day hassle-free.
                                    </p>
                                </article>

                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="mx-auto max-w-2xl px-4 sm:px-6">
                        <div className="py-12 md:py-20">
                            <div className="space-y-3 text-center">
                                <div className="relative inline-flex">
                                    <svg
                                        className="absolute -left-6 -top-2 -z-10"
                                        width="40"
                                        height="49"
                                        viewBox="0 0 40 49"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M22.7976 -0.000136375L39.9352 23.4746L33.4178 31.7234L13.7686 11.4275L22.7976 -0.000136375ZM9.34947 17.0206L26.4871 40.4953L19.9697 48.7441L0.320491 28.4482L9.34947 17.0206Z"
                                            fill="#D1D5DB"
                                        ></path>
                                    </svg>
                                    <img
                                        alt="Large testimonial"
                                        loading="lazy"
                                        width="48"
                                        height="48"
                                        decoding="async"
                                        data-nimg="1"
                                        className="rounded-full"
                                        style={{ color: "transparent" }}
                                        src="images/avatar1.jpg"
                                    />
                                </div>
                                <p className="text-2xl font-bold text-gray-900">
                                    &ldquo;Inking revolutionized our studio! No more double bookings, and scheduling is a breeze. Clients love it, and our workflow has never been smoother!&rdquo;
                                </p>
                                <div className="text-sm font-medium text-gray-500">
                                    <span className="text-gray-700">Sarah Thompson</span>
                                    <span className="text-gray-400">/</span>
                                    <a className="text-blue-500" href="#0">
                                        {" "}
                                        Owner of Inked Studio
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="mx-auto max-w-6xl px-4 sm:px-6">
                        <div className="relative overflow-hidden rounded-2xl text-center shadow-xl before:pointer-events-none before:absolute before:inset-0 before:-z-10 before:rounded-2xl before:bg-gray-900"

                        >
                            <div
                                className="absolute bottom-0 left-1/2 -z-10 -translate-x-1/2 translate-y-1/2"
                                aria-hidden="true"
                            >
                                <div className="h-56 w-[480px] rounded-full border-[20px] border-blue-500 blur-3xl"></div>
                            </div>
                            <div
                                className="pointer-events-none absolute left-1/2 top-0 -z-10 -translate-x-1/2 transform"
                                aria-hidden="true"
                            >
                                <img
                                    alt="Stripes"
                                    loading="lazy"
                                    width="768"
                                    height="432"
                                    decoding="async"
                                    data-nimg="1"
                                    className="max-w-none"
                                    style={{
                                        color: "transparent", background: ''
                                    }}
                                    src="images/media-stripes-dark.f52135c0.svg"
                                />
                            </div>
                            <div className="px-4 py-12 md:px-12 md:py-20">
                                <h2 className="mb-6 border-y text-3xl font-bold text-gray-200 [border-image:linear-gradient(to_right,transparent,theme(colors.slate.700/.7),transparent)1] md:mb-12 md:text-4xl">
                                    Streamline Appointments with Inking
                                </h2>
                                <div className="mx-auto max-w-xs sm:flex sm:max-w-none sm:justify-center">
                                    <a
                                        className="btn group mb-4 w-full bg-gradient-to-t from-blue-600 to-blue-500 bg-[length:100%_100%] bg-[bottom] text-white shadow hover:bg-[length:100%_150%] sm:mb-0 sm:w-auto"
                                        href="/signup"
                                        style={{ background: 'white', color: 'black' }}
                                    >
                                        <span className="relative inline-flex items-center">
                                            Start Your Free Trial
                                            {/* <span className="ml-1 tracking-normal text-blue-300 transition-transform group-hover:translate-x-0.5">
                                                -&gt;
                                            </span> */}
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <footer>
                <div className="mx-auto max-w-6xl px-4 sm:px-6">
                    <div className="grid gap-10 py-8 sm:grid-cols-12 md:py-12 border-t [border-image:linear-gradient(to_right,transparent,theme(colors.slate.200),transparent)1] justify-center">
                        <div className="space-y-2 sm:col-span-12 lg:col-span-4">
                            {/* <div>
                                <a
                                    className="inline-flex"
                                    aria-label="Cruip"
                                    href="simple.cruip.html"
                                >
                                    <svg width="29" height="33" viewBox="0 0 29 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.17157 37.3066C3.4846 37.3066 1.83277 37.3066 0.0403702 37.3066C0.110661 32.808 1.97335 29.2935 5.62845 26.3413C5.32972 26.1656 5.06613 26.0777 4.8904 25.9196C0.637834 22.1239 -0.961267 17.3969 0.567548 11.9142C2.11394 6.36128 5.96232 3.11035 11.6383 2.16143C14.6783 1.65182 17.5954 2.21414 20.2664 3.79568C20.9693 4.21742 21.3735 4.11198 21.8831 3.54965C23.0077 2.31957 24.1851 1.15979 25.2922 0C26.5926 1.30037 27.7699 2.51288 29 3.74296C27.6469 5.0609 26.2411 6.43156 24.8001 7.83737C25.0813 8.34698 25.2922 8.73357 25.503 9.12017C30.2652 17.924 23.9742 28.9245 13.993 29.0299C8.91452 29.0826 5.43515 32.9135 5.29457 36.8497C5.277 36.9727 5.20671 37.1133 5.17157 37.3066ZM13.5537 7.22233C8.98481 7.22233 5.24186 10.9653 5.25943 15.5166C5.29458 20.0679 8.96725 23.7933 13.4483 23.8284C18.0874 23.8636 21.8304 20.1558 21.8655 15.5166C21.8831 10.9829 18.1401 7.22233 13.5537 7.22233Z" fill="black" className="fill-black-500" fillRule="evenodd" />
                                        <path d="M27.0494 37.5175C27.0143 46.2686 19.1066 51.3295 13.624 50.7672C13.624 49.1329 13.624 47.4811 13.624 45.7766C18.5092 45.2143 21.3032 42.5081 21.9358 37.5175C23.6228 37.5175 25.2922 37.5175 27.0494 37.5175Z" fill="black" className="fill-black-500" fillRule="evenodd" />
                                        <path d="M15.0825 38.0798C15.0825 38.6773 14.608 39.1518 14.0106 39.1518C13.6767 39.1518 13.378 38.9936 13.1847 38.7652L10.4961 40.3994C10.4609 40.417 10.4082 40.417 10.3906 40.3818L10.1973 40.0655C10.1798 40.0304 10.1798 39.9777 10.2149 39.9601L12.9387 38.2907C12.9211 38.2204 12.9211 38.1501 12.9211 38.0798C12.9211 37.5878 13.255 37.1836 13.7118 37.0606V32.316C13.7118 32.2809 13.747 32.2457 13.7821 32.2457H14.1512C14.1863 32.2457 14.2215 32.2809 14.2215 32.316V37.043C14.7135 37.1485 15.0825 37.5702 15.0825 38.0798Z" fill="black" className="fill-black-500" fillRule="evenodd" />
                                    </svg>
                                </a>
                            </div>
                            <div className="text-sm text-gray-600">
                                &copy; Inking.com - All rights reserved.
                            </div> */}
                        </div>
                        {/* <div className="space-y-2 sm:col-span-6 md:col-span-3 lg:col-span-2">
                            <h3 className="text-sm font-medium">Product</h3>
                            <ul className="space-y-2 text-sm">
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Features
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Integrations
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Pricing &amp; Plans
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Changelog
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Our method
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-2 sm:col-span-6 md:col-span-3 lg:col-span-2">
                            <h3 className="text-sm font-medium">Company</h3>
                            <ul className="space-y-2 text-sm">
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        About us
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Diversity &amp; Inclusion
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Careers
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Financial statements
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-2 sm:col-span-6 md:col-span-3 lg:col-span-2">
                            <h3 className="text-sm font-medium">Resources</h3>
                            <ul className="space-y-2 text-sm">
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Community
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Terms of service
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="text-gray-600 transition hover:text-gray-900"
                                        href="#0"
                                    >
                                        Report a vulnerability
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="space-y-2 sm:col-span-6 md:col-span-3 lg:col-span-2">
                            <h3 className="text-sm font-medium">Social</h3>
                            <ul className="flex gap-1">
                                <li>
                                    <a
                                        className="flex items-center justify-center text-blue-500 transition hover:text-blue-600"
                                        aria-label="Twitter"
                                        href="#0"
                                    >
                                        <svg
                                            className="h-8 w-8 fill-black"
                                            viewBox="0 0 32 32"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="m13.063 9 3.495 4.475L20.601 9h2.454l-5.359 5.931L24 23h-4.938l-3.866-4.893L10.771 23H8.316l5.735-6.342L8 9h5.063Zm-.74 1.347h-1.457l8.875 11.232h1.36l-8.778-11.232Z"></path>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="flex items-center justify-center text-blue-500 transition hover:text-blue-600"
                                        aria-label="Medium"
                                        href="#0"
                                    >
                                        <svg
                                            className="h-8 w-8 fill-black"
                                            viewBox="0 0 32 32"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M23 8H9a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1Zm-1.708 3.791-.858.823a.251.251 0 0 0-.1.241V18.9a.251.251 0 0 0 .1.241l.838.823v.181h-4.215v-.181l.868-.843c.085-.085.085-.11.085-.241v-4.887l-2.41 6.131h-.329l-2.81-6.13V18.1a.567.567 0 0 0 .156.472l1.129 1.37v.181h-3.2v-.181l1.129-1.37a.547.547 0 0 0 .146-.472v-4.749a.416.416 0 0 0-.138-.351l-1-1.209v-.181H13.8l2.4 5.283 2.122-5.283h2.971l-.001.181Z"></path>
                                        </svg>
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="flex items-center justify-center text-blue-500 transition hover:text-blue-600"
                                        aria-label="Github"
                                        href="#0"
                                    >
                                        <svg
                                            className="h-8 w-8 fill-black"
                                            viewBox="0 0 32 32"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M16 8.2c-4.4 0-8 3.6-8 8 0 3.5 2.3 6.5 5.5 7.6.4.1.5-.2.5-.4V22c-2.2.5-2.7-1-2.7-1-.4-.9-.9-1.2-.9-1.2-.7-.5.1-.5.1-.5.8.1 1.2.8 1.2.8.7 1.3 1.9.9 2.3.7.1-.5.3-.9.5-1.1-1.8-.2-3.6-.9-3.6-4 0-.9.3-1.6.8-2.1-.1-.2-.4-1 .1-2.1 0 0 .7-.2 2.2.8.6-.2 1.3-.3 2-.3s1.4.1 2 .3c1.5-1 2.2-.8 2.2-.8.4 1.1.2 1.9.1 2.1.5.6.8 1.3.8 2.1 0 3.1-1.9 3.7-3.7 3.9.3.4.6.9.6 1.6v2.2c0 .2.1.5.6.4 3.2-1.1 5.5-4.1 5.5-7.6-.1-4.4-3.7-8-8.1-8z"></path>
                                        </svg>
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                <div className="relative -mt-16 h-80 w-full" aria-hidden="true">
                    <div className="space-y-2 sm:col-span-12 lg:col-span-4">
                        <div>
                            <a
                                className="inline-flex"
                                aria-label="Cruip"
                                href="simple.cruip.html"
                            >
                                <svg width="29" height="33" viewBox="0 0 29 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.17157 37.3066C3.4846 37.3066 1.83277 37.3066 0.0403702 37.3066C0.110661 32.808 1.97335 29.2935 5.62845 26.3413C5.32972 26.1656 5.06613 26.0777 4.8904 25.9196C0.637834 22.1239 -0.961267 17.3969 0.567548 11.9142C2.11394 6.36128 5.96232 3.11035 11.6383 2.16143C14.6783 1.65182 17.5954 2.21414 20.2664 3.79568C20.9693 4.21742 21.3735 4.11198 21.8831 3.54965C23.0077 2.31957 24.1851 1.15979 25.2922 0C26.5926 1.30037 27.7699 2.51288 29 3.74296C27.6469 5.0609 26.2411 6.43156 24.8001 7.83737C25.0813 8.34698 25.2922 8.73357 25.503 9.12017C30.2652 17.924 23.9742 28.9245 13.993 29.0299C8.91452 29.0826 5.43515 32.9135 5.29457 36.8497C5.277 36.9727 5.20671 37.1133 5.17157 37.3066ZM13.5537 7.22233C8.98481 7.22233 5.24186 10.9653 5.25943 15.5166C5.29458 20.0679 8.96725 23.7933 13.4483 23.8284C18.0874 23.8636 21.8304 20.1558 21.8655 15.5166C21.8831 10.9829 18.1401 7.22233 13.5537 7.22233Z" fill="black" className="fill-black-500" fillRule="evenodd" />
                                    <path d="M27.0494 37.5175C27.0143 46.2686 19.1066 51.3295 13.624 50.7672C13.624 49.1329 13.624 47.4811 13.624 45.7766C18.5092 45.2143 21.3032 42.5081 21.9358 37.5175C23.6228 37.5175 25.2922 37.5175 27.0494 37.5175Z" fill="black" className="fill-black-500" fillRule="evenodd" />
                                    <path d="M15.0825 38.0798C15.0825 38.6773 14.608 39.1518 14.0106 39.1518C13.6767 39.1518 13.378 38.9936 13.1847 38.7652L10.4961 40.3994C10.4609 40.417 10.4082 40.417 10.3906 40.3818L10.1973 40.0655C10.1798 40.0304 10.1798 39.9777 10.2149 39.9601L12.9387 38.2907C12.9211 38.2204 12.9211 38.1501 12.9211 38.0798C12.9211 37.5878 13.255 37.1836 13.7118 37.0606V32.316C13.7118 32.2809 13.747 32.2457 13.7821 32.2457H14.1512C14.1863 32.2457 14.2215 32.2809 14.2215 32.316V37.043C14.7135 37.1485 15.0825 37.5702 15.0825 38.0798Z" fill="black" className="fill-black-500" fillRule="evenodd" />
                                </svg>
                            </a>
                        </div>
                        <div className="text-sm text-gray-600">
                            &copy; Inking.com - All rights reserved.
                        </div>
                    </div>
                    <div className="pointer-events-none absolute left-1/2 -z-10 -translate-x-1/2 text-center text-[348px] font-bold leading-none before:bg-gradient-to-b before:from-gray-200 before:to-gray-100/30 before:to-80% before:bg-clip-text before:text-transparent before:content-['Simple'] after:absolute after:inset-0 after:bg-gray-300/70 after:bg-clip-text after:text-transparent after:mix-blend-darken after:content-['Simple'] after:[text-shadow:0_1px_0_white]"></div>
                    <div
                        className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-2/3"
                        aria-hidden="true"
                    >
                        <div className="h-56 w-56 rounded-full border-[20px] border-blue-700 blur-[80px]"></div>
                    </div>
                </div>
            </footer>
        </>
    );
};