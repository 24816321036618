import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Sidebar } from "./Sidebar";
import { Header } from "./Header";
import { Footer } from "./Footer";
import PropTypes from "prop-types";
import style from "./index.module.scss";
import { useLocation } from "react-router-dom";

const { Content } = Layout;

const CabinetComponent = ({ children, user, logout }) => {
  const location = useLocation();
  let menuList = [
    {
      label: "Events",
      path: "/home",
      icon: "home",
    },
  ];

  if (user.type === "admin") {
    menuList.push({
      label: "Users",
      path: "/users",
      icon: "team",
    });
  }

  if (user.type !== "worker") {
    menuList.push({
      label: "Studios",
      path: "/studios",
      icon: "appstore-o",
    });
    menuList.push({
      label: "Clients",
      path: "/clients",
      icon: "user",
    });
    menuList.push({
      label: "Available Slots",
      path: "/slots",
      icon: "calendar",
    });
  }
  const [headerTitle, setHeaderTitle] = useState("");

  useEffect(() => {
    if (location?.pathname.includes("studios")) {
      setHeaderTitle("Studios");
    } else if (location?.pathname.includes("users")) {
      setHeaderTitle("Users");
    } else if (location?.pathname.includes("clients")) {
      setHeaderTitle("Clients");
    } else if (location?.pathname.includes("slots")) {
      setHeaderTitle("Slots");
    } else if (location?.pathname === "/home") {
      setHeaderTitle("Dashboard");
    }
  }, [location?.pathname]);
  return (
    <Layout style={{ minHeight: "100vh" }}>
      {/* <Layout.Sider>
        <Header user={user} logout={logout} menuList={menuList} />
      </Layout.Sider> */}
      <Layout>
        <Sidebar menuList={menuList} />
        <Layout style={{ padding: "0 24px 24px" }}>
          <h1 style={{ fontSize: "20px", fontWeight: "500", padding: "20px" }}>
            {headerTitle}
          </h1>
          <Content
            className={style.cabinetContent}
            style={{
              background: "#fff",
              padding: 24,
              margin: "24px 0 0",
              minHeight: 300,
              borderRadius: "20px",
              marginTop: "10px",
            }}
          >
            {children}
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </Layout>
  );
};

CabinetComponent.propTypes = {
  menuList: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
};

export const Cabinet = CabinetComponent;
