import { api } from "../../../helpers/api";
export const USERS_REQUEST_PROCESS = "USERS_REQUEST_PROCESS";
export const USERS_REQUEST_ERROR = "USERS_REQUEST_ERROR";
export const USERS_REQUEST_SUCCESS = "USERS_REQUEST_SUCCESS";
// const currentUser = JSON.parse(localStorage.getItem("authUserData"))
export const usersRequestProcess = () => ({
  type: USERS_REQUEST_PROCESS,
});

export const usersRequestSuccess = (data) => ({
  type: USERS_REQUEST_SUCCESS,
  data,
});

export const usersRequestError = (error) => ({
  type: USERS_REQUEST_ERROR,
  error,
});

export const usersFetchRequest = () => async (dispatch) => {
  try {
    dispatch(usersRequestProcess());
    const currentUser = JSON.parse(localStorage.getItem("authUserData"));

    const data = await api("get", `users`);

    if (Array.isArray(data) && data.length > 0) {
      const myData = data.sort((a, b) => {
        let fa = a.firstName?.toLowerCase(),
          fb = b.lastName?.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      dispatch(usersRequestSuccess(myData));
    } else {
      console.error("Data is not an array or is empty", data);
      dispatch(usersRequestError("No valid user data found."));
    }
  } catch (error) {
    dispatch(usersRequestError(error.response ? error.response.data : error));
  }
};
