import React from "react";
import { Form, Icon, Input, Button, Checkbox } from "antd";
import style from "./index.module.scss";
import { Link } from "react-router-dom";

const SigninFormComponent = (props) => {
  const { getFieldDecorator } = props.form;
  const [visible, setVisible] = React.useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        props.onSubmit(values);
      }
    });
  };

  const toggleVisibility = () => {
    setVisible(!visible);
  };

  return (
    <Form onSubmit={handleSubmit} className={style.signinForm}>
      {/* <img
        src="/img/yourlogo.png"
        alt="logo"
        width="70vw"
        style={{ display: "flex", margin: "auto", marginBottom: "16px" }}
      /> */}
      <h1 className={style.authHeader}>Login</h1>
      <Form.Item>
        {getFieldDecorator("email", {
          rules: [
            { required: true, message: "Please input your email!" },
            { type: "email", message: "The input is not valid E-mail!" },
          ],
        })(
          <Input
            placeholder="Email"
            style={{ borderRadius: "4px", height: "40px" }}
          />
        )}
      </Form.Item>
      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
      >
        {getFieldDecorator("password", {
          rules: [{ required: true, message: "Please input your password!" }],
        })(
          <Input
            type={visible ? "text" : "password"}
            placeholder="Password"
            style={{ borderRadius: "4px", height: "40px" }}
            suffix={
              <Icon
                type={visible ? "eye-invisible" : "eye"}
                onClick={toggleVisibility}
                style={{ cursor: "pointer" }}
              />
            }
          />
        )}
      </Form.Item>
      <Form.Item style={{ marginBottom: "4px", marginTop: "-15px" }}>
        {getFieldDecorator("rememberMe", {
          valuePropName: "checked",
          initialValue: false,
        })(<Checkbox style={{ marginBottom: "4px" }}>Remember me</Checkbox>)}
        <Link
          to="/restore-password"
          style={{
            float: "right",
            color: "#FF4D4F",
            textDecoration: "underline",
            marginTop: "0px",
          }}
        >
          Forgot password?
        </Link>
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          loading={props.isLoading}
          style={{
            width: "100%",
            borderRadius: "4px",
            height: "40px",
            background: "#333333",
            border: "none",
           
          }}
          htmlType="submit"
        >
          Login
        </Button>
        <div className={style.singupLinks}>
          Don’t have an account?
          <Link to="/signup" style={{ color: "#07B464" }}>{` Sign Up `}</Link>
          {/* or <Link to="/restore-password">Forgot password</Link> */}
        </div>
      </Form.Item>
    </Form>
  );
};

export const SigninForm = Form.create({ name: "signinForm" })(
  SigninFormComponent
);
