import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { clientsFetchRequest } from "../actions";
import { usersFetchRequest } from "../../Users/actions";
import { Loader } from "../../../Common/Loader";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Popconfirm,
  Divider,
  Select,
  Row,
  Col,
  notification,
} from "antd";
import style from "./index.module.scss";
import { api } from "../../../../helpers/api";
import { getFullDate } from "../../../../helpers/_helper";

const { Option } = Select;

export const Clients = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [clientList, setClientsList] = useState([]);
  const [isModalVisibleClient, setIsModalVisibleClient] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [clientDetails, setClientDetails] = useState({});
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("authUserData"))
  );
  useEffect(() => {
    if (!isModalVisible) {
      setEditId(null);
      props.form.resetFields();
    }
  }, [isModalVisible]);
  useEffect(() => {
    props.clientsFetchRequest();
    // eslint-disable-next-line
  }, []);

  const showAddModal = () => {
    setIsModalVisible(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        setIsLoading(true);
        // values.company = currentUser.user.company._id;
        try {
          if (editId) {
            await api("put", `clients/${editId}`, values);
            notification.open({
              message: "Client updated successfully!",
              className: "custom-class",
              type: "success",
              style: {
                width: 600,
              },
            });
            setEditId(null);
          } else {
            await api("post", "clients", values);
            notification.open({
              message: "Client added successfully!",
              className: "custom-class",
              type: "success",
              style: {
                width: 600,
              },
            });
          }
          props.clientsFetchRequest();
          setIsModalVisible(false);
          setIsLoading(false);
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            notification.open({
              message: error.response.data.message,
              className: "custom-class",
              type: "error",
              style: {
                width: 600,
              },
            });
          }
          setIsLoading(false);
        }
      }
    });
  };

  const handleDelete = async (key) => {
    await api("delete", `clients/${key}`);
    props.clientsFetchRequest();
  };

  const handleEdit = (values) => {
    const dob = new moment(values.dob);
    setIsModalVisible(true);
    setEditId(values._id);
    props.form.setFieldsValue({ ...values, dob });
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setIsModalVisibleClient(false);
  };

  const sorting = (data) => {
    const myData = data.sort((a, b) => {
      let fa = a.email?.toLowerCase(),
        fb = b.email?.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return myData;
  };

  const handleClient = (value) => {
    setClientDetails(value);
    setIsModalVisibleClient(true);
  };

  const handleClientChange = async (value) => {
    if (value !== "") {
      const clients = value && (await api("get", `/search/clients/${value}`));
      console.log(clients, "clients");
      const SortedClients = sorting(clients);
      setClientsList(SortedClients);
    } else {
      const data = await api("get", `clients`);
      const SortedClients = sorting(data);
      setClientsList(SortedClients);
    }
  };

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "fisrtName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => getFullDate(date),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => getFullDate(date),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record._id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];
  const { getFieldDecorator } = props.form;
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginLeft: "20px",
        }}
      >
        <h1 style={{ fontSize: "18px", fontWeight: "600", color: "#7C7E8A" }}>
          Clients
        </h1>
        <div>
          <Button
            type="primary"
            onClick={showAddModal}
            style={{
              background: "#333333",
              border: "none",
              height: "40px",
              width: "120px",
            }}
          >
            Add Client
          </Button>
        </div>
        {/* <Row
          gutter={{
            xs: 8,
            sm: 16,
            md: 24,
            lg: 32,
          }}
          // style={{ display: "flex", marginBottom: "20px" }}
        >
          <Col xs={12} sm={8} md={3} lg={3} xl={3}>
            <Button type="primary" onClick={showAddModal}>
              Add Client
            </Button>
          </Col> */}
        {/* <Col
            xs={12}
            sm={10}
            md={6}
            lg={6}
            xl={6}
            style={{ textAlign: "right", marginLeft: "auto" }}
          > */}
        {/* <Select
              showSearch
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              notFoundContent={null}
              placeholder="Search Client"
              style={{ width: "20vw" }}
              onSearch={handleClientChange}
            >
              {clientList.map((client) => (
                <Option key={client._id} onClick={() => handleClient(client)}>
                  {client.lastName} {client.firstName}
                </Option>
              ))}
            </Select> */}
        {/* </Col> */}
        {/* </Row> */}
      </div>
      <div className={style.clientsList}>
        <Loader isLoading={props.isLoading} style={{}}>
          <Table
            columns={columns}
            dataSource={props.clients}
            rowKey="_id"
            scroll={{
              x: 600,
            }}
          />
        </Loader>
      </div>
      <Modal
        title="Add Client"
        visible={isModalVisible}
        footer=""
        onCancel={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator("firstName", {
              rules: [
                { required: true, message: "Please input client First Name!" },
              ],
            })(<Input placeholder="First Name" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("lastName", {
              rules: [
                { required: true, message: "Please input client Last Name!" },
              ],
            })(<Input placeholder="Last Name" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("email", {
              rules: [
                { required: true, message: "Please input client email!" },
              ],
            })(<Input placeholder="Email" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("contact", {
              rules: [
                { required: true, message: "Please input client contact!" },
              ],
            })(<Input placeholder="Contact number" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("notes")(<Input placeholder="Notes" />)}
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#333333", border: "none" }}
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Client Information"
        visible={isModalVisibleClient}
        footer=""
        onCancel={handleClose}
      >
        <p>Client First Name : {clientDetails?.firstName} </p>
        <p>Client Last Name : {clientDetails?.lastName} </p>
        <p>Client Contact Number : {clientDetails?.contact} </p>
        <p>Client Email : {clientDetails?.email}</p>{" "}
      </Modal>
    </div>
  );
};
const WrappedForm = Form.create({ name: "horizontal_login" })(Clients);

Clients.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  clients: PropTypes.array,
  users: PropTypes.array,
  clientsFetchRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.cabinet.studios.isLoading,
  clients: state.cabinet.clients.data,
  users: state.cabinet.users.data,
});

export const ClientsContainer = withRouter(
  connect(mapStateToProps, { clientsFetchRequest, usersFetchRequest })(
    WrappedForm
  )
);
