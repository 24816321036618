import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { routes } from "./routes";
import { AuthRoute } from "./feature/Common/AuthRoute";
import { Layout } from "./feature/Common/Layout";
import { history } from "./helpers/history";
import { Provider } from "react-redux";
import { store } from "./store";
import { LandingPage } from "./feature/Common/LandingPage"; // Ensure LandingPage is properly imported
import { getAuthUserData } from "./helpers/auth";
import { signinSuccess, logout } from "./feature/Auth/Signin/actions";
import { initAuthInterceptor } from "./helpers/api";

// Set up user authentication data (if available)
const userAuth = getAuthUserData();
if (userAuth) {
  store.dispatch(signinSuccess(userAuth));
}

initAuthInterceptor(store, logout);

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          {/* Add LandingPage as the default route */}
          <Route exact path="/" component={LandingPage} />

          {/* Your other routes */}
          <Route>
            <Layout>
              <Switch>
                {routes.map((item, i) =>
                  item.isAuth ? (
                    <AuthRoute
                      path={item.path}
                      component={item.component}
                      key={i}
                      exact={item.exact}
                    />
                  ) : (
                    <Route
                      path={item.path}
                      component={item.component}
                      key={i}
                      exact={item.exact}
                    />
                  )
                )}
              </Switch>
            </Layout>
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;
