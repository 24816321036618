import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Popconfirm,
  Select,
  Upload,
  message,
  notification,
  Row,
  Col,
} from "antd";
import { Calendar, momentLocalizer } from "react-big-calendar";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Loader } from "../../../Common/Loader";
import PropTypes from "prop-types";
import { api } from "../../../../helpers/api";
import { connect } from "react-redux";
import { getFullDate } from "../../../../helpers/_helper";
import moment from "moment";
import style from "./index.module.scss";
import { usersFetchRequest } from "../actions";
import { withRouter, useLocation } from "react-router-dom";
import { PaymentModal } from "../../../Common/PaymentModal/container";

const { Option } = Select;
const today = new Date();

moment.locale("en - au", {
  week: {
    dow: 1,
    doy: 1,
  },
});
const localizer = momentLocalizer(moment);
const DnDCalender = withDragAndDrop(Calendar);
const openBase64ImageInNewTab = (base64Image) => {
  var image = new Image();
  image.src = base64Image;

  var w = window.open("about:blank");
  setTimeout(function () {
    w.document.write(image.outerHTML);
    w.document.close();
  }, 0);
};

const ImageGallery = (props) => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const downloadImage2 = () => {
    if (!selectedImage) {
      console.error("No image selected for download.");
      return;
    }

    const link = document.createElement("a");
    link.href = selectedImage.startsWith("data:image")
      ? selectedImage
      : selectedImage;
    link.download = `downloaded_image_${Date.now()}.png`;
    link.click();
  };

  useEffect(() => {
    if (!props.propName || !props.images) {
      throw new Error("PropName and images are required for gallery");
    }
    if (props.images[props.propName]) {
      const fetchImages = async () => {
        const imgget = async (key) => await api("get", `get-files/${key}`);
        const fetchedImages = await Promise.all(
          props.images[props.propName].map(imgget)
        );
        setImages(fetchedImages);
      };
      fetchImages();
    }
  }, [props.images, props.propName]);

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const imgStyle = {
    width: "150px",
    height: "150px",
    objectFit: "cover",
    cursor: "pointer",
    borderRadius: "8px",
    margin: "5px",
  };

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      style={{
        marginBottom: images.length > 0 ? "20px" : "0",
        backgroundColor: images.length > 0 ? "#f5f5f5" : "transparent",
        padding: "10px",
        borderRadius: "8px",
      }}
    >
      <Slider {...sliderSettings}>
        {images.map((e, i) => (
          <div key={i} style={{ padding: "5px" }}>
            <img
              src={e.data}
              alt={`slide-${i}`}
              style={imgStyle}
              onClick={() => openModal(e.data)}
            />
          </div>
        ))}
      </Slider>
      <Modal
        visible={isModalOpen}
        onCancel={closeModal}
        width="90vw"
        centered
        bodyStyle={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0",
          height: "80vh",
          overflow: "hidden",
        }}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Cancel
          </Button>,
          <a
            href={`${selectedImage}`}
            target="_blank"
            rel="noreferrer"
            style={{
              display: "inline-block",
              padding: "6px 8px",
              backgroundColor: "black",
              color: "white",
              border: "none",
              borderRadius: "4px",
              textDecoration: "none",
              textAlign: "center",
              marginLeft: "10px",
              cursor: "pointer",
            }}
          >
            Download
          </a>,
        ]}
      >
        <img
          src={selectedImage}
          alt="Selected"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "contain",
          }}
        />
      </Modal>
    </div>
  );
};
const EventHistory = (props) => {
  if (!props.event.history) return null;
  return props.event.history.map((e, i) => {
    return (
      <div key={i}>
        <span>
          <b>
            {e.updatedBy.firstName} {e.updatedBy.lastName}
          </b>{" "}
          changed{" "}
        </span>
        {e.price && <span>price to "{e.price}", </span>}
        {e.description && <span>description to "{e.description}", </span>}
        {e.user && (
          <span>
            assignee to "{e.user.firstName} {e.user.lastName}",{" "}
          </span>
        )}
        {e.studio && <span>studio to "{e.studio.name}", </span>}
        {e.client && <span>client to "{e.client?.name}", </span>}
        on {getFullDate(e.updatedAt)}
        <hr />
      </div>
    );
  });
};

export const Home = (props) => {
  const eventObj = {
    attachements: [],
    bodyPlacementImages: [],
    referenceImages: [],
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedDay, setSelectedDay] = useState();
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [clientsList, setClientsList] = useState(null);
  const [studiosList, setStudiosList] = useState(null);
  const [studioSearchList, setStudioSearchList] = useState([]);
  const [userEvents, setUserEvents] = useState(null);
  const [events, setEvents] = useState(null);
  const [userIds, setUserIds] = useState(null);
  const [eventsList, setEventsList] = useState(null);
  const [images, setImageFilesList] = useState([]);
  const [bodyPlacementImages, setBodyPlacementImagesList] = useState([]);
  const [referenceImages, setReferenceImagesList] = useState([]);
  const [currentEventDetails, setCurrentEventDetails] = useState(eventObj);
  const [eventSearchResults, setEventSearchResults] = useState([]);
  const [distinctUserList, setDistinctUserList] = useState([]);
  const [dateS, setDate] = useState();
  const [isPaymentModalVisible, setIsPaymentModalVisible] = useState(false);
  const today = new Date();
  const [start, setStart] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  ); // First date of current month
  const [end, setEnd] = useState(
    new Date(today.getFullYear(), today.getMonth() + 1, 0)
  ); // Last date of current month
  const fetchEventsByDateRange = async () => {
    // let events = await api("get", `events/${currentUser.user.company}`);
    // let events = await api("get", `events`);
    // setEventsList(events);
    // events = events.map((e) => ({
    //   id: e._id,
    //   title: `${(e.client || {}).firstName || ""} : ${
    //     (e.studio || {}).name || ""
    //   }`,
    //   start: new Date(e.startTime),
    //   end: new Date(e.endTime),
    //   user: `${(e.user || {}).firstName || ""} ${
    //     (e.user || {}).lastName || ""
    //   }`,
    //   color: (e.user || {}).color || "#000",
    //   client: e.client,
    //   description: e.description,
    //   studio: e.studio?.name,
    // }));
    // events &&
    let events = await api("get", `events?startTime=${start}&endTime=${end}`);

    // let events = await api("get", `events/${currentUser.user.company}`);
    // let events = await api("get", `events`);
    setEventsList(events);
    events = events.map((e) => ({
      id: e._id,
      title: `${(e.client || {}).firstName || ""} : ${
        (e.studio || {}).name || ""
      }`,
      start: new Date(e.startTime),
      end: new Date(e.endTime),
      user: `${(e.user || {}).firstName || ""} ${
        (e.user || {}).lastName || ""
      }`,
      color: (e.user || {}).color || "#000",
      client: e.client,
      description: e.description,
      studio: e.studio?.name,
    }));
    events &&
      events.map((e, index) => {
        if (
          currentUser &&
          currentUser.user &&
          currentUser.user.type === "worker" &&
          index === 0
        ) {
          setSelectedDay(e.start);
        }
      });
    setEvents(events);
  };

  const calendarColorCodes = [
    "546a76",
    "88a0a8",
    "b4ceb3",
    "dbd3c9",
    "fad4d8",
    "177e89",
    "084c61",
    "323031",
    "ee8434",
    "8a2e32",
    "ae8799",
    "717ec3",
    "496ddb",
    "631d76",
    "9e4770",
    "8ea4d2",
    "6279b8",
    "49516f",
    "496f5d",
    "4c9f70",
    "646e78",
    "8d98a7",
    "dcccbb",
    "eab464",
    "a7754d",
    "3a3335",
    "d81e5b",
    "f0544f",
    "fdf0d5",
    "c6d8d3",
  ];

  const uploaderOnChange = async (info, func) => {
    func(info.fileList);
    console.log("hi", info);
  };

  const beforeUpload = (file) => {
    const allowedTypes = [
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/svg+xml",
      "image/webp",
      "image/svg",
    ];
    const isImage =
      allowedTypes.includes(file.type) || file.name.endsWith(".sbvfg");
    if (!isImage) {
      message.error(`${file.name} is not an image file`);
      throw new Error(`${file.name} is not an image file`);
    }
    return true;
  };

  const attacehmentsuploader = {
    beforeUpload,
    onChange: async (info) => {
      uploaderOnChange(info, setImageFilesList);
    },
  };

  const bodyPlacementImagesuploader = {
    beforeUpload,
    onChange: async (info) => {
      uploaderOnChange(info, setBodyPlacementImagesList);
    },
  };

  const referenceImagesuploader = {
    beforeUpload,
    onChange: async (info) => {
      uploaderOnChange(info, setReferenceImagesList);
    },
  };

  const eventStyleGetter = function (event, start, end, isSelected) {
    events.forEach((event) => {
      const user = event.user;
      if (!distinctUserList) setDistinctUserList([]);
      if (!distinctUserList.includes(user))
        setDistinctUserList([...distinctUserList, user]);
    });
    const userIndex = distinctUserList.indexOf(event.user);
    var backgroundColor = "#" + calendarColorCodes[userIndex];
    var style = {
      backgroundColor: event.color,
      borderRadius: "0px",
      opacity: 0.8,
      color: "white",
      border: "0px",
      display: "block",
    };
    return {
      style: style,
    };
  };
  const sorting = (data) => {
    const myData = data.sort((a, b) => {
      let fa = a.name?.toLowerCase(),
        fb = b.name?.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return myData;
  };

  const sortingUser = (data) => {
    const myData = data.sort((a, b) => {
      let fa = a.firstName?.toLowerCase();
      let fb = b.firstName?.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return myData;
  };
  const [currentUser, setCurrentUser] = useState(
    JSON.parse(localStorage.getItem("authUserData"))
  );
  const fetchData = async function () {
    const users = await api("get", `users`);
    const studios = await api("get", `studios`);
    const clients = await api("get", `clients`);
    fetchEventsByDateRange();
    // let events = await api("get", `events/${currentUser.user.company}`);
    // let events = await api("get", `events`);
    // setEventsList(events);
    // events = events.map((e) => ({
    //   id: e._id,
    //   title: `${(e.client || {}).firstName || ""} : ${
    //     (e.studio || {}).name || ""
    //   }`,
    //   start: new Date(e.startTime),
    //   end: new Date(e.endTime),
    //   user: `${(e.user || {}).firstName || ""} ${
    //     (e.user || {}).lastName || ""
    //   }`,
    //   color: (e.user || {}).color || "#000",
    //   client: e.client,
    //   description: e.description,
    //   studio: e.studio?.name,
    // }));
    // events &&
    //   events.map((e, index) => {
    //     if (
    //       currentUser &&
    //       currentUser.user &&
    //       currentUser.user.type === "worker" &&
    //       index === 0
    //     ) {
    //       setSelectedDay(e.start);
    //     }
    //   });

    const SortedClients = sorting(clients);
    const SortedUSers = sortingUser(users);
    setUsersList(SortedUSers);
    setStudiosList(studios);
    setClientsList(SortedClients);
    // setEvents(events);
  };

  useEffect(async () => {
    props.usersFetchRequest();
    fetchData();
  }, []);

  const addEvent = () => {
    setCurrentEventDetails(eventObj);
    setEditId(null);
    setIsModalVisible(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          setIsLoading(true);
          setSelectedDay(new Date(values.startTime));

          const formData = new FormData();
          formData.append("client", values.client);
          formData.append("description", values.description);
          formData.append("price", values.price);
          formData.append("user", values.user);
          formData.append("studio", values.studio);
          formData.append("startTime", values.startTime);
          formData.append("endTime", values.endTime);
          // formData.append("company", currentUser.user.company._id);

          images.forEach((file) =>
            formData.append("attachements", file.originFileObj)
          );
          bodyPlacementImages.forEach((file) =>
            formData.append("bodyPlacementImages", file.originFileObj)
          );
          referenceImages.forEach((file) =>
            formData.append("referenceImages", file.originFileObj)
          );

          if (editId) {
            await api("put", `events/${editId}`, formData);
            notification.open({
              message: "Event updated successfully!",
              className: "custom-class",
              type: "success",
              style: {
                width: 600,
              },
            });
            setEditId(null);
          } else {
            await api("post", "events", formData);
            notification.open({
              message: "Event added successfully!",
              className: "custom-class",
              type: "success",
              style: {
                width: 600,
              },
            });
          }
          props.usersFetchRequest();
          props.form.resetFields();

          setIsLoading(false);
          setIsModalVisible(false);

          fetchData();
          setCurrentEventDetails({});
        } catch (error) {
          if (error.message === "Request failed with status code 507")
            notification.open({
              message: "Attachments limit reached",
              description: "You cannot upload more attachments here.",
              className: "custom-class",
              type: "error",
              style: {
                width: 600,
              },
            });
          else if (error.message === "Request failed with status code 502")
            notification.open({
              message: "No space in studio",
              type: "error",
              description: "No space is available for this time",
              className: "custom-class",
              style: {
                width: 600,
              },
            });
          else {
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              notification.open({
                message: error.response.data.message,
                className: "custom-class",
                type: "error",
                style: {
                  width: 600,
                },
              });
            }
          }
          setIsLoading(false);
        }
      }
    });
  };

  // const getBase64 = async (file) => {
  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = () => resolve(reader.result);
  //     reader.onerror = (error) => reject(error);
  //   });
  // };

  const handleDelete = async (key) => {
    setIsModalVisible(false);
    setIsDetailModalVisible(false);
    await api("delete", `events/${key}`);
    fetchData();
  };

  const handleEdit = (values) => {
    if (values) {
      setCurrentEventDetails(eventObj);
      setIsDetailModalVisible(false);
      setIsModalVisible(true);
      console.log("edit====", values._id);
      setEditId(values._id);
      props.form.setFieldsValue({
        client: (values.client || {})._id || null,
        description: values.description,
        price: values.price,
        startTime: moment(values.startTime),
        endTime: moment(values.endTime),
        user: (values.user || {})._id || null,
        studio: (values.studio || {})._id,
      });
      return false;
    } else return;
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setIsDetailModalVisible(false);
    return false;
  };

  const onSelectEvent = async (event) => {
    const eventHistory = await api("get", `event-history/${event.id}`);
    const eventDetail = await api("get", `events/${event.id}`);
    setCurrentEventDetails({ ...eventDetail[0], history: eventHistory });
    setIsDetailModalVisible(true);
    return false;
  };
  const onEventDrop = async ({ event, start, end }) => {
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const nextEvents = [...events];
    nextEvents.splice(idx, 1, updatedEvent);
    setEvents(nextEvents);
    const payload = { startTime: moment(start), endTime: moment(end) };
    await api("put", `events/${event.id}`, payload);
    fetchData();
  };

  const resizeEvent = async ({ event, start, end }) => {
    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });
    const payload = { startTime: moment(start), endTime: moment(end) };

    await api("put", `events/${event.id}`, payload);
    setEvents(nextEvents);
  };
  const handleChange = (value) => {
    setEventSearchResults(
      events.filter(
        (e) =>
          e.title.includes(value) ||
          e.client.contact.includes(value) ||
          e.description.includes(value)
      )
    );
  };
  const handleStudioChange = (value) => {
    setStudioSearchList(studiosList.filter((e) => e.name.includes(value)));
  };

  // const handleSelection = (value) => {
  //   const filterKey = Object.keys(value)[0];
  //   let filtersUsers = {};
  //   const filtersValue = value[filterKey];
  //   if (filtersUsers[filterKey] === undefined) {
  //     setFilters((prev) => ({ ...prev, [filterKey]: [filtersValue] }));
  //   } else {
  //     filtersUsers[filterKey].push(filtersValue);
  //     setFilters((prev) => ({ ...prev, [filterKey]: filtersUsers[filterKey] }));
  //   }
  //   // handleFilters(filtersUsers);
  // };

  // const handleFilters = () => {
  //   // let filtered = [];
  //   // console.log("filters===", filters);
  //   // Object.keys(filters).map((k) => {
  //   //   console.log(k);
  //   //   console.log(filters[k]);
  //   //   filtered = events.filter(
  //   //     (ev) => ev[k] == filters[k].map((value) => value)
  //   //   );
  //   // });
  //   // console.log("filtered===", filtered);
  //   // setEvents(filtered);

  //   let tempResults = [];
  //   const filterKeys = Object.keys(filters);
  //   events.map((item) => {
  //     for (let fkey of filterKeys) {
  //       console.log("fkey====", fkey);
  //       const query = filters[fkey].filter((fItem) => {
  //         console.log("item[fkey]", item[fkey]);
  //         console.log("fItem", fItem);
  //         return item[fkey].includes(fItem);
  //       });
  //       console.log("query", query);
  //       if (query.length > 0) {
  //         const isExist = tempResults.findIndex(
  //           (resultItem) => resultItem === item
  //         );
  //         if (isExist === -1) {
  //           tempResults.push(item);
  //         }
  //       }
  //     }

  //     // filterKeys.forEach((filter) => {
  //     // console.log("filter", filter);
  //     // const filterKey = Object.keys(filter);
  //     // console.log("filterKey", filterKey[0]);
  //     // console.log("item[filter]", item[filter]);
  //     // console.log("filter[filterKey]", filter[filterKey[0]]);
  //     // const queries = filter[filterKey[0]];
  //     // const results = queries.filter((query) => item[filterKey[0]] === query);
  //     // if (results.length > 0) {
  //     //   console.log("item", item);
  //     //   const isExist = tempResults.findIndex(
  //     //     (resultItem) => resultItem.user === item.user
  //     //   );
  //     //   if (isExist === -1) {
  //     //     tempResults.push(item);
  //     //   }
  //     // }
  //     // console.log("results", results);
  //     // return results;
  //     // });
  //     // console.log('matched', matched)
  //     // return matched;
  //   });
  //   setEvents(tempResults);
  //   console.log("tempResults====", tempResults);
  // };

  const handleUserChange = async (value) => {
    const users = value && (await api("get", `/search/users/${value}`));
    setUsersList(users);
  };

  const handleCustomerChange = async (value) => {
    if (value !== "") {
      const clients = value && (await api("get", `/search/clients/${value}`));
      const SortedClients = sorting(clients);
      setClientsList(SortedClients);
    }
  };

  // const handleStudioSelection = async (value) => {
  //   console.log("value", value);
  //   // setUserIds(value);
  //   if (value.lenght > 0) {
  //     const studioEvents = eventsList.filter((x) =>
  //       value.includes(x.studio._id)
  //     );
  //     const studiosEvents = studioEvents.map((e) => ({
  //       id: e._id,
  //       title: `${(e.client || {}).name || ""} : ${
  //         (e.studio || {}).name || ""
  //       }`,
  //       start: new Date(e.startTime),
  //       end: new Date(e.endTime),
  //       user: `${(e.user || {}).firstName || ""} ${
  //         (e.user || {}).lastName || ""
  //       }`,
  //       color: (e.user || {}).color || "#000",
  //       client: e.client,
  //       description: e.description
  //     }));
  //     console.log("studiosEvents", studiosEvents);
  //     setEvents(events);
  //   } else {
  //     let events = await api("get", `events/`);
  //     events = events.map((e) => ({
  //       id: e._id,
  //       title: `${(e.client || {}).name || ""} : ${
  //         (e.studio || {}).name || ""
  //       }`,
  //       start: new Date(e.startTime),
  //       end: new Date(e.endTime),
  //       user: `${(e.user || {}).firstName || ""} ${
  //         (e.user || {}).lastName || ""
  //       }`,
  //       color: (e.user || {}).color || "#000",
  //       client: e.client,
  //       description: e.description,
  //       studio: e.studio?.name
  //     }));
  //     console.log("events++++++,,,,", events);
  //     setEvents(events);
  //   }
  //   // setUserEvents(studiosEvents);
  //   // studiosEvents &&
  //   //   studiosEvents.map((e, index) => {
  //   //     if (index === 0) {
  //   //       setSelectedDay(e.start);
  //   //     }
  //   //   });
  // };

  const handleFilters = async (obj_key, value) => {
    let filteredEvents = [];
    if (value.length > 0) {
      const filteredEvent = eventsList.filter((x) =>
        value.includes(x[obj_key]._id)
      );
      filteredEvents = filteredEvent.map((e) => ({
        id: e._id,
        title: `${(e.client || {}).name || ""} : ${
          (e.studio || {}).name || ""
        }`,
        start: new Date(e.startTime),
        end: new Date(e.endTime),
        user: `${(e.user || {}).firstName || ""} ${
          (e.user || {}).lastName || ""
        }`,
        color: (e.user || {}).color || "#000",
        client: e.client,
        description: e.description,
      }));
      setEvents(filteredEvents);
    } else {
      let events = await api("get", `events`);
      events = events.map((e) => ({
        id: e._id,
        title: `${(e.client || {}).firstName || ""} : ${
          (e.studio || {}).name || ""
        }`,
        start: new Date(e.startTime),
        end: new Date(e.endTime),
        user: `${(e.user || {}).firstName || ""} ${
          (e.user || {}).lastName || ""
        }`,
        color: (e.user || {}).color || "#000",
        client: e.client,
        description: e.description,
        studio: e.studio?.name,
      }));
      setEvents(events);
    }
  };

  const onDelete = (index, propName) => {
    if (!propName) throw new Error("PropName is required in delete method");

    let attachements = currentEventDetails[propName].filter(
      (e, i) => i !== index
    );
    setCurrentEventDetails({ attachements });
    return false;
  };
  const { getFieldDecorator } = props.form;
  const dateSelect = (value) => {
    value = moment(value).add(30, "m");
    props.form.setFieldsValue({
      endTime: value,
    });
  };
  useEffect(() => {
    props.form.setFieldsValue({
      startTime: moment(),
    });
    props.form.setFieldsValue({
      endTime: moment().add(30, "m"),
    });
  }, []);

  const selectedSlot = ({ start, end }) => {
    start = moment(start);
    props.form.setFieldsValue({
      startTime: start,
    });
    end = moment(end);
    props.form.setFieldsValue({
      endTime: end,
    });

    setCurrentEventDetails(eventObj);
    setEditId(null);
    setIsModalVisible(true);
  };

  const handleOpenPaymentModal = () => {
    setIsPaymentModalVisible(true);
  };

  const handleClosePaymentModal = () => {
    setIsPaymentModalVisible(false);
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const billing = queryParams.get("billing");
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const fetchUserCompany = async () => {
      if (!currentUser || !currentUser.user) {
        console.log("Current user is not defined");
        return;
      }

      let userCompany = await api("get", `companies`);

      if (
        (billing === "subscription" ||
          currentUser.user.company.stripeInfo.isSubscribed === false) &&
        userCompany[0]?.stripeInfo.isSubscribed === false
      ) {
        setOpen(true);
      }
    };

    fetchUserCompany();
  }, []);
  useEffect(() => {
    fetchEventsByDateRange();
  }, [start, end]);
  return (
    <>
      <PaymentModal visible={open} onClose={setOpen} />
      <div
        className={
          isPaymentModalVisible ? "home-container blurred" : "home-container"
        }
      >
        <h1 style={{ fontSize: "18px", fontWeight: "600", color: "#7C7E8A" }}>
          Events
        </h1>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div>
            {currentUser.user && currentUser.user.type !== "worker" && (
              <Select
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
                mode="multiple"
                placeholder="Select Studios"
                style={{ width: "20vw" }}
                onSearch={handleStudioChange}
                onChange={(value) => handleFilters("studio", value)}
              >
                {studioSearchList.map((studio) => (
                  <Option
                    key={studio._id}
                    // style={{ color: d.color }}
                    // onClick={() => handleSelection({ studio: studio.name })}
                  >
                    {studio.name}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          <div>
            {currentUser.user && currentUser.user.type !== "worker" && (
              <Select
                showSearch
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                notFoundContent={null}
                mode="multiple"
                placeholder="Select User"
                style={{ width: "20vw" }}
                onSearch={handleUserChange}
                onChange={(value) => handleFilters("user", value)}
              >
                {usersList &&
                  usersList.map((user) => (
                    <Option
                      value={user._id}
                      key={user._id}
                      // onClick={() => handleSelection({ user: user.firstName })}
                    >
                      {user.firstName} {user.lastName}
                    </Option>
                  ))}
              </Select>
            )}
          </div>
          <div>
            <Select
              showSearch
              placeholder="Search events"
              defaultActiveFirstOption={false}
              showArrow={false}
              style={{ width: "20vw" }}
              filterOption={false}
              onSearch={handleChange}
              notFoundContent={null}
            >
              {eventSearchResults.map((d) => (
                <Option
                  key={d.title}
                  style={{ color: d.color }}
                  onClick={(event) => onSelectEvent(d)}
                >
                  {d.title}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            {currentUser &&
              currentUser.user &&
              currentUser.user.type !== "worker" && (
                <Button
                  type="primary"
                  onClick={addEvent}
                  style={{
                    background: "#333333",
                    border: "none",
                    height: "40px",
                    width: "120px",
                  }}
                >
                  Add Event
                </Button>
              )}
          </div>
        </div>
        <DnDCalender
          className={style.calendarContainer}
          selectable={true}
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          onEventDrop={onEventDrop}
          resizable={true}
          onEventResize={resizeEvent}
          onSelectEvent={(event) => onSelectEvent(event)}
          eventPropGetter={eventStyleGetter}
          min={
            new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9)
          }
          date={selectedDay}
          onNavigate={(date) => {
            setSelectedDay(date);
            const start = new Date(date.getFullYear(), date.getMonth(), 1);
            const end = new Date(
              date.getFullYear(),
              date.getMonth() + 1,
              0,
              23,
              59,
              59
            );
            setStart(start);
            setEnd(end);
          }}
          onSelectSlot={selectedSlot}
        />
        {isDetailModalVisible && currentEventDetails && currentUser ? (
          <Modal
            title="Event Details"
            visible={true}
            footer=""
            onCancel={handleClose}
          >
            <p>
              Client FirstName :{" "}
              {currentEventDetails.client &&
                currentEventDetails.client.firstName}
            </p>
            {currentUser.user && currentUser.user.type !== "worker" && (
              <p>
                Client LastName :{" "}
                {currentEventDetails.client &&
                  currentEventDetails.client.lastName}{" "}
              </p>
            )}
            {currentUser.user && currentUser.user.type !== "worker" && (
              <p>
                Client Contact Number :{" "}
                {currentEventDetails.client &&
                  currentEventDetails.client.contact}{" "}
              </p>
            )}
            {currentUser.user && currentUser.user.type !== "worker" && (
              <p>
                Client Email :{" "}
                {currentEventDetails.client && currentEventDetails.client.email}{" "}
              </p>
            )}
            {currentUser.user && currentUser.user.type !== "worker" && (
              <p>Price : {currentEventDetails.price} </p>
            )}

            <p>Studio : {((currentEventDetails || {}).studio || {}).name} </p>
            <p>
              Assigned To : {((currentEventDetails || {}).user || {}).firstName}{" "}
              {((currentEventDetails || {}).user || {}).lastName}{" "}
            </p>
            <p>
              Start Time :{" "}
              {moment(currentEventDetails.startTime).format(
                "MMMM Do YYYY, h:mm A"
              )}{" "}
            </p>
            <p>
              End Time :{" "}
              {moment(currentEventDetails.endTime).format(
                "MMMM Do YYYY, h:mm A"
              )}{" "}
            </p>
            <p>Description : {currentEventDetails.description} </p>

            <p>Attachements:</p>
            <ImageGallery
              images={currentEventDetails}
              propName="attachements"
            ></ImageGallery>
            <p>Body Placement Images:</p>
            <ImageGallery
              images={currentEventDetails}
              propName="bodyPlacementImages"
            ></ImageGallery>
            <p>Reference Images:</p>
            <ImageGallery
              images={currentEventDetails}
              propName="referenceImages"
            ></ImageGallery>
            {currentUser.user && currentUser.user.type !== "worker" && (
              <Button
                type="primary"
                onClick={() => handleEdit(currentEventDetails)}
                style={{ background: "#333333", border: "none" }}
              >
                Edit
              </Button>
            )}
            {currentUser.user && currentUser.user.type !== "worker" && (
              <Button
                type="danger"
                onClick={() => handleDelete(currentEventDetails._id)}
                style={{ marginLeft: "10px" }}
              >
                Delete
              </Button>
            )}
            {currentUser.user && currentUser.user.type !== "worker" && (
              <EventHistory event={currentEventDetails}></EventHistory>
            )}
          </Modal>
        ) : (
          ""
        )}
        {currentEventDetails ? (
          <Modal
            title="Event"
            visible={isModalVisible}
            footer=""
            onCancel={handleClose}
            style={{ height: "50vh" }}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Item>
                {getFieldDecorator("client", {
                  rules: [
                    {
                      required: true,
                      message: "Please select client!",
                    },
                  ],
                })(
                  <Select
                    mode="single"
                    placeholder="Select Client"
                    showSearch
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    notFoundContent={null}
                    onSearch={handleCustomerChange}
                  >
                    {clientsList &&
                      clientsList.length &&
                      clientsList.map((client) => (
                        <Select.Option value={client._id} key={client._id}>
                          {client.firstName} {client.lastName}
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("price", {
                  rules: [{ required: true, message: "Please input price!" }],
                })(<Input placeholder="Price" />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("user", {
                  rules: [
                    {
                      required: true,
                      message: "Please select user!",
                    },
                  ],
                })(
                  <Select mode="single" placeholder="Select User">
                    {usersList &&
                      usersList.length &&
                      usersList.map((user) => (
                        <Select.Option value={user._id} key={user._id}>
                          {user.firstName} {user.lastName}
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("studio", {
                  rules: [
                    {
                      required: true,
                      message: "Please select studio!",
                    },
                  ],
                })(
                  <Select mode="single" placeholder="Select Studio">
                    {studiosList &&
                      studiosList.length &&
                      studiosList.map((studio) => (
                        <Select.Option value={studio._id} key={studio._id}>
                          {studio.name}
                        </Select.Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("description", {
                  rules: [
                    { required: true, message: "Please input description!" },
                  ],
                })(<Input placeholder="Description" />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("startTime", {
                  rules: [
                    { required: true, message: "Please input start time!" },
                  ],
                })(
                  <DatePicker
                    showTime={{
                      hideDisabledOptions: true,
                      disabledHours: () => {
                        return [
                          ...Array(10).keys(),
                          ...Array.from({ length: 5 }, (_, i) => i + 20),
                        ];
                      },
                      disabledMinutes: () => {
                        return [];
                      },
                      disabledSeconds: () => {
                        return [];
                      },
                    }}
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder="Start Time"
                    onChange={(value) => dateSelect(value)}
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("endTime", {
                  rules: [
                    { required: true, message: "Please input end time!" },
                  ],
                })(
                  <DatePicker
                    showTime={{
                      hideDisabledOptions: true,
                      disabledHours: () => {
                        return [
                          ...Array(10).keys(),
                          ...Array.from({ length: 5 }, (_, i) => i + 20),
                        ];
                      },
                      disabledMinutes: () => {
                        return [];
                      },
                      disabledSeconds: () => {
                        return [];
                      },
                    }}
                    disabledDate={(current) =>
                      current && current < moment().startOf("day")
                    }
                    format="YYYY-MM-DD HH:mm:ss"
                    placeholder="End Time"
                  />
                )}
              </Form.Item>
              <p>Attacements : </p>
              <ImageGallery
                images={currentEventDetails}
                propName="attachements"
                isEdit={true}
                onDelete={onDelete}
              ></ImageGallery>
              <Upload {...attacehmentsuploader}>
                <Button>Upload Attacehment Images</Button>
              </Upload>
              <p>Body Placement Images : </p>
              <ImageGallery
                images={currentEventDetails}
                propName="bodyPlacementImages"
                isEdit={true}
                onDelete={onDelete}
              ></ImageGallery>
              <Upload {...bodyPlacementImagesuploader}>
                <Button>Upload Body Placement Images</Button>
              </Upload>
              <p>Reference Images : </p>
              <ImageGallery
                images={currentEventDetails}
                propName="referenceImages"
                isEdit={true}
                onDelete={onDelete}
              ></ImageGallery>
              <Upload {...referenceImagesuploader}>
                <Button>Upload Reference Images</Button>
              </Upload>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ background: "#333333", border: "none" }}
                  loading={isLoading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Modal>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

const WrappedForm = Form.create({ name: "horizontal_login" })(Home);

Home.propTypes = {
  users: PropTypes.array,
  usersFetchRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.cabinet.users.data,
});

export const HomeContainer = withRouter(
  connect(mapStateToProps, { usersFetchRequest })(WrappedForm)
);
