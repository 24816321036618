import { createStore, applyMiddleware, compose } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import config from "./config/app";
import { reducers } from "./reducers";

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;

const middlewares = [thunk];
if (config.LOGGER_ENABLE) {
  middlewares.push(logger);
}

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(...middlewares))
);
