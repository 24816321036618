import React, { useState } from "react";
import { api } from "../../../../helpers/api";
import { getAuthUserData } from "../../../../helpers/auth";
import { logoutHandler } from "../../../Auth/Signin/actions";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    notification,
} from "antd";
const PaymentModalContainer = (props) => {
    const userAuth = getAuthUserData();
    const [loading, setIsLoading] = useState(false);
    if (!props.visible) return null;


    const onSubmit = async () => {
        try {
            setIsLoading(true);
            const checkout = await api("post", `stripe`, {
                // company: userAuth.user.company
            });
            if (checkout) {
                window.location.href = checkout.url;
            }
        } catch (error) {
            notification.open({
                message: error.response.data.message,
                className: "custom-class",
                type: "error",
                style: {
                    width: 600,
                },
            });
        }
        setIsLoading(false);
    };

    const handleLogout = () => {
        props.logoutHandler();
        props.onClose();
    };

    const modalStyle = {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
    };

    const contentStyle = {
        background: "#f8f9fa",
        padding: "40px",
        borderRadius: "8px",
        textAlign: "center",
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)",
    };

    const buttonStyle = {
        backgroundColor: "#007bff",
        color: "white",
        border: "none",
        padding: "12px 24px",
        borderRadius: "5px",
        cursor: "pointer",
        margin: "5px",
        transition: "background-color 0.3s ease",
        fontWeight: "bold",
    };

    return (
        <div style={modalStyle} onClick={props.onClose}>
            <div style={contentStyle} onClick={onSubmit}>
                <h2 style={{ color: "#333" }}>Trial Period Ended</h2>
                <p style={{ color: "#555" }}>
                    Your 30-days trial period has ended. Please subscribe to continue
                    using our services.
                </p>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                    }}
                >
                    <button
                        style={{
                            ...buttonStyle,

                            backgroundColor: "#333333",
                            border: 'none',
                        }} onClick={onSubmit}>
                        Subscribe Now
                    </button>
                    <button
                        style={{
                            ...buttonStyle,
                            background: "red",
                            marginLeft: "10px",
                        }}
                        onClick={() => {
                            handleLogout();
                        }}
                    >
                        Logout
                    </button>
                </div>
            </div>
        </div>
    );
};

PaymentModalContainer.propTypes = {
    isAuth: PropTypes.bool.isRequired,
    logoutHandler: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    isAuth: state.auth.signin.isAuth,
    user: state.auth.signin.user,
});

export const PaymentModal = connect(mapStateToProps, { logoutHandler })(
    PaymentModalContainer
);
