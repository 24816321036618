import { api } from "../../../helpers/api";

export const STUDIOS_REQUEST_PROCESS = "STUDIOS_REQUEST_PROCESS";
export const STUDIOS_REQUEST_ERROR = "STUDIOS_REQUEST_ERROR";
export const STUDIOS_REQUEST_SUCCESS = "STUDIOS_REQUEST_SUCCESS";

export const clientsRequestProcess = () => ({
  type: STUDIOS_REQUEST_PROCESS,
});

export const clientsRequestSuccess = (data) => ({
  type: STUDIOS_REQUEST_SUCCESS,
  data,
});

export const clientsRequestError = (error) => ({
  type: STUDIOS_REQUEST_ERROR,
  error,
});

export const clientsFetchRequest = () => async (dispatch) => {
  try {
    dispatch(clientsRequestProcess());
    const currentUser = JSON.parse(localStorage.getItem("authUserData"))

    const data = await api("get", `clients`);
    const myData = data.sort((a, b) => {
      let fa = a.email.toLowerCase(),
        fb = b.email.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    dispatch(clientsRequestSuccess(data));
  } catch (error) {
    dispatch(clientsRequestError(error.response ? error.response.data : error));
  }
};
