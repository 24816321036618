import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { usersFetchRequest } from "../actions";
import { Loader } from "../../../Common/Loader";
import { Table, Button, Modal, Form, Input, Popconfirm, Divider } from "antd";
import style from "./index.module.scss";
import { api } from "../../../../helpers/api";
import { getFullDate } from "../../../../helpers/_helper";

export const Projects = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    props.usersFetchRequest();
    // eslint-disable-next-line
  }, []);

  const showAddModal = () => {
    setIsModalVisible(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        if (editId) {
          await api("put", `projects/${editId}`, values);
          setEditId(null);
        } else await api("post", "projects", values);
        props.usersFetchRequest();
        setIsModalVisible(false);
      }
    });
  };

  const handleDelete = async (key) => {
    await api("delete", `projects/${key}`);
    props.usersFetchRequest();
  };

  const handleEdit = (values) => {
    setIsModalVisible(true);
    setEditId(values._id);
    props.form.setFieldsValue(values);
  };

  const handleClose = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Total Hours",
      dataIndex: "totalHours",
      key: "totalHours",
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => getFullDate(date),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date) => getFullDate(date),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 120,
      render: (text, record) => (
        <span>
          <a onClick={() => handleEdit(record)}>Edit</a>
          <Divider type="vertical" />
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record._id)}
          >
            <a>Delete</a>
          </Popconfirm>
        </span>
      ),
    },
  ];
  const { getFieldDecorator } = props.form;

  return (
    <div>
      <h1>Projects</h1>
      <Button type="primary" onClick={showAddModal}>
        Add Project
      </Button>
      <div className={style.usersList}>
        <Loader isLoading={props.isLoading}>
          <Table columns={columns} dataSource={props.users} rowKey="_id" />
        </Loader>
      </div>

      <Modal
        title="Add project"
        visible={isModalVisible}
        footer=""
        onCancel={handleClose}
      >
        <Form onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator("name", {
              rules: [
                { required: true, message: "Please input project name!" },
              ],
            })(<Input placeholder="Project Name" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("budget", {
              rules: [
                { required: true, message: "Please input project budget!" },
              ],
            })(<Input placeholder="Budget" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("location", {
              rules: [
                { required: true, message: "Please input project location!" },
              ],
            })(<Input placeholder="Location" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("totalHours", {
              rules: [
                { required: true, message: "Please input project hours!" },
              ],
            })(<Input placeholder="Total Hours" />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("clientName", {
              rules: [
                {
                  required: true,
                  message: "Please input project client name!",
                },
              ],
            })(<Input placeholder="Client Name" />)}
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ background: "#333333", border: "none" }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
const WrappedForm = Form.create({ name: "horizontal_login" })(Projects);

Projects.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.array,
  usersFetchRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isLoading: state.cabinet.users.isLoading,
  users: state.cabinet.users.data,
});

export const ProjectsContainer = withRouter(
  connect(mapStateToProps, { usersFetchRequest })(WrappedForm)
);
