import React, { useEffect } from "react";
import { Route, Redirect, useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getAuthUserData } from "../../../helpers/auth";
import { api } from "../../../helpers/api";
const AuthRouteContainer = ({ component: Component, ...rest }) => {
  const userAuth = getAuthUserData();
  const location = useLocation();
  const history = useHistory(); // Initialize history

  useEffect(() => {
    const fetchCompanyData = async () => {
      if (userAuth.user.company) {
        return await api("get", `companies`);
      }
      return null;
    };
    const checkSubscriptionStatus = async () => {
      const company = await fetchCompanyData();
      if (company) {
        if (company[0]?.stripeInfo.isSubscribed) {
          if (location.pathname?.includes("/home")) history.push("/home");
        } else history.push("/home?billing=subscription");
      }
    };

    checkSubscriptionStatus();
  }, [location.pathname]);

  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isAuth ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

AuthRouteContainer.propTypes = {
  isAuth: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuth: state.auth.signin.isAuth,
});

export const AuthRoute = connect(mapStateToProps)(AuthRouteContainer);
