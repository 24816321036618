import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Menu, Icon, Tooltip } from "antd";
import PropTypes from "prop-types";
import "./index.css";
import { logoutHandler } from "../../../../../Auth/Signin/actions";
import { connect } from "react-redux";
const { Sider } = Layout;
const { SubMenu } = Menu;

const SidebarComponent = (props) => {
  return (
    <Sider width={85} style={{ background: "#333333" }} className="display">
      <Menu
        mode="vertical"
        defaultSelectedKeys={["0"]}
        style={{
          // height: "100%",
          borderRight: 0,
          background: "#333333",
          // color: "#494848",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          marginTop: "20px",
        }}
      >
        {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
        <div
          style={{
            borderRadius: "8px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            marginBottom: "40px",
          }}
        >
          <Link
            to={"/home"}
            style={{
              display: "flex",
              justifyContent: "center",
              background: "#333333 !important",
            }}
          >
            <img
              src="/img/logo.svg"
              style={{ width: "29px", height: "50px" }}
            />
          </Link>
        </div>

        {props.menuList.map((item, i) => {
          return (
            <Menu.Item
              key={i}
              style={{
                borderRadius: "8px",
                height: "55px",
                width: "55px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                margin: "0 auto",
                marginBottom: "10px",
              }}
            >
              <Tooltip
                title={item.label}
                placement="right"
                style={{ background: "white" }}
              >
                <Link
                  to={item.path}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    height: "100%",
                  }}
                >
                  <Icon
                    type={item.icon}
                    style={{
                      color: "white",
                      margin: "0 auto",
                      fontSize:'20px'
                      // width: "30px",
                      // height: "30px",
                    }}
                  />
                </Link>
              </Tooltip>
            </Menu.Item>
          );
        })}
        {/* </div> */}

        <SubMenu
          key="username"
          title={
            <span>
              <Icon
                type="setting"
                style={{
                  color: "white",
                  fontSize:'20px'
                }}
              />
              {/* {user.firstName} {user.lastName} */}
            </span>
          }
          style={{
            position: "absolute",
            bottom: 7,
            left: "50%",
            transform: "translateX(-50%)",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Menu.Item
            key="1"
            style={
              {
                // borderRadius: "4px",
                // background: "#333333",
              }
            }
            onClick={() => {
              props.logoutHandler();
            }}
          >
            Logout
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

SidebarComponent.propTypes = {
  menuList: PropTypes.array,
  isAuth: PropTypes.bool.isRequired,
  logoutHandler: PropTypes.func.isRequired,
};

// export const Sidebar = SidebarComponent;

const mapStateToProps = (state) => ({
  isAuth: state.auth.signin.isAuth,
  user: state.auth.signin.user,
});

export const Sidebar = connect(mapStateToProps, { logoutHandler })(
  SidebarComponent
);
