import { api } from "../../../helpers/api";

export const STUDIOS_REQUEST_PROCESS = "STUDIOS_REQUEST_PROCESS";
export const STUDIOS_REQUEST_ERROR = "STUDIOS_REQUEST_ERROR";
export const STUDIOS_REQUEST_SUCCESS = "STUDIOS_REQUEST_SUCCESS";

export const studiosRequestProcess = () => ({
  type: STUDIOS_REQUEST_PROCESS,
});

export const studiosRequestSuccess = (data) => ({
  type: STUDIOS_REQUEST_SUCCESS,
  data,
});

export const studiosRequestError = (error) => ({
  type: STUDIOS_REQUEST_ERROR,
  error,
});

export const studiosFetchRequest = () => async (dispatch) => {
  try {
    dispatch(studiosRequestProcess());
    const currentUser = JSON.parse(localStorage.getItem("authUserData"))

    const data = await api("get", `studios`);

    dispatch(studiosRequestSuccess(data));
  } catch (error) {
    dispatch(studiosRequestError(error.response ? error.response.data : error));
  }
};
