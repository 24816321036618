import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import { Form, Select, Row, Col, Alert } from "antd";
import { Calendar, Views, momentLocalizer } from "react-big-calendar";
import React, { useEffect, useState } from "react";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import PropTypes from "prop-types";
import { api } from "../../../../helpers/api";
import { connect } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import style from "./index.module.scss";
import { usersFetchRequest } from "../actions";
import { withRouter } from "react-router-dom";

const { Option } = Select;
const today = new Date();
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/London");

moment.locale("en - au", {
    week: {
        dow: 1,
        doy: 1,
    },
});
const localizer = momentLocalizer(moment);
const DnDCalender = withDragAndDrop(Calendar);

export const Slot = (props) => {
    const [events, setEvents] = useState(null);
    const [studios, setStudios] = useState([]);
    const [studiosList, setStudiosList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [message, setMessage] = useState("");
    const [view, setView] = useState("month");
    const [currentUser, setCurrentUser] = useState(
        JSON.parse(localStorage.getItem("authUserData"))
    );
    const fetchData = async function () {
        const users = await api("get", `users`);
        setUserList(users);
        setUsersList(users);
        const data = await api("get", `studios`);
        setStudios(data);
        setStudiosList(data);
        let events = await api("get", `/available-slots-studio/${data[0]?._id}`);
        setMessage(`Showing Available Slots of ${data[0]?.name} Studio`);

        events =
            events &&
            events.map((e, index) => {
                const available =
                    e.numOccupiedRooms && e.studioTotalSlots - e.numOccupiedRooms;
                return {
                    start: dayjs(new Date(e.start)).toDate(),
                    end: dayjs(new Date(e.end)).toDate(),
                    title: e.numOccupiedRooms
                        ? `Available ${available}/${e.studioTotalSlots}`
                        : `Available ${e.studioTotalSlots}/${e.studioTotalSlots}`,
                };
            });
        setEvents(events);

    };
    const fetchDataMonth = async function (val = undefined) {

        const users = await api("get", `users`);
        setUserList(users);
        setUsersList(users);
        const data = await api("get", `studios`);
        setStudios(data);
        setStudiosList(data);
        let events = await api("get", `/available-slots-studio/${data[0]?._id}`);
        setMessage(`Showing Available Slots of ${data[0]?.name} Studio`);
        const totalSlots = data[0]?.slots;

        const groupedEvents = {};
        events.forEach((e) => {
            const date = dayjs(new Date(e.start)).format("YYYY-MM-DD");
            const occupied = e.numOccupiedRooms || 0;

            if (!groupedEvents[date]) {
                groupedEvents[date] = {
                    start: new Date(e.start),
                    end: new Date(e.end),
                    totalOccupied: occupied,
                };
            } else {
                groupedEvents[date].start = new Date(
                    Math.min(groupedEvents[date].start, new Date(e.start))
                );
                groupedEvents[date].end = new Date(
                    Math.max(groupedEvents[date].end, new Date(e.end))
                );
                groupedEvents[date].totalOccupied += occupied;
            }
        });

        const consolidatedEvents = Object.keys(groupedEvents).map((date) => {
            const { start, end, totalOccupied } = groupedEvents[date];
            return {
                start,
                end,
                title:
                    totalOccupied && totalOccupied > 0
                        ? `${totalSlots - totalOccupied}/${totalSlots} Available`
                        : `${totalSlots}/${totalSlots} Available`,
            };
        });
        setEvents(consolidatedEvents);
    };
    const handleChange = async (value) => {
        if (value) {
            setUserList(
                userList.filter(
                    (e) => e.firstName.toLowerCase().includes(value.toLowerCase()) || e.lastName.toLowerCase().includes(value.toLowerCase())
                )
            );
        } else {
            const users = await api("get", `users`);
            setUserList(users);
        }
    };

    const handleStudioChange = async (value) => {
        if (value) {
            setStudiosList(studiosList.filter((e) => e.name.includes(value)));
        } else {
            const data = await api("get", `studios/`);
            setStudiosList(data);
        }
    };

    useEffect(async () => {
        const data = await api("get", `studios`);
        props.usersFetchRequest(data[0]?._id);
        if (view === 'month')
            fetchDataMonth()
        else
            fetchData();
    }, [view]);

    const handleChangeStudio = async (val) => {
        let events = await api("get", `available-slots-studio/${val}`);
        const data = await api("get", `studios`);
        const selectedStudio = data.filter((studio) => studio?._id == val);
        setMessage(`Showing Available Slots of ${selectedStudio[0]} Studio`);
        events =
            events &&
            events.map((e, index) => {
                const available =
                    e.numOccupiedRooms && e.studioTotalSlots - e.numOccupiedRooms;
                return {
                    start: dayjs(new Date(e.start)).toDate(),
                    end: dayjs(new Date(e.end)).toDate(),
                    title: e.numOccupiedRooms
                        ? `Available ${available}/${e.studioTotalSlots}`
                        : `Available ${e.studioTotalSlots}/${e.studioTotalSlots}`,
                };
            });
        setEvents(events);
    };
    const handleChangeStudioMonth = async (val) => {
        let events = await api("get", `available-slots-studio/${val}`);
        const data = await api("get", `studios`);
        const selectedStudio = data.filter((studio) => studio?._id == val);
        setMessage(`Showing Available Slots of ${selectedStudio[0]?.name} Studio`);
        const totalSlots = selectedStudio[0]?.slots
        const groupedEvents = {};
        events.forEach((e) => {
            const date = dayjs(new Date(e.start)).format("YYYY-MM-DD");
            const occupied = e.numOccupiedRooms || 0;

            if (!groupedEvents[date]) {
                groupedEvents[date] = {
                    start: new Date(e.start),
                    end: new Date(e.end),
                    totalOccupied: occupied,
                };
            } else {
                groupedEvents[date].start = new Date(
                    Math.min(groupedEvents[date].start, new Date(e.start))
                );
                groupedEvents[date].end = new Date(
                    Math.max(groupedEvents[date].end, new Date(e.end))
                );
                groupedEvents[date].totalOccupied += occupied;
            }
        });

        const consolidatedEvents = Object.keys(groupedEvents).map((date) => {
            const { start, end, totalOccupied } = groupedEvents[date];
            return {
                start,
                end,
                title:
                    totalOccupied && totalOccupied > 0
                        ? `${totalSlots - totalOccupied}/${totalSlots} Available`
                        : `${totalSlots}/${totalSlots} Available`,
            };
        });
        setEvents(consolidatedEvents);
    };

    const handleUserChange = async (val) => {
        let events = await api("get", `available-slots-user/${val}`);
        const users = await api("get", `users`);
        const selectedUser = users.filter((user) => user._id === val);
        setMessage(
            `Showing Available Slots of ${selectedUser[0]?.firstName}-${selectedUser[0]?.lastName} User`
        );
        events =
            events &&
            events.map((e, index) => {
                return {
                    start: dayjs(new Date(e.start)).toDate(),
                    end: dayjs(new Date(e.end)).toDate(),
                    title: "Available",
                };
            });
        setEvents(events);
    };

    return (
        <div>
            <h1 style={{ fontSize: "18px", fontWeight: "600", color: "#7C7E8A" }}>
                Available Slots
            </h1>            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <div>
                    {usersList.length > 0 && (
                        <Select
                            showSearch
                            placeholder="Search Users"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            style={{ width: "20vw" }}
                            filterOption={false}
                            onSearch={handleChange}
                            notFoundContent={null}
                        >
                            {userList.map((d) => (
                                <Option key={d._id} onClick={(d) => handleUserChange(d?.key)}>
                                    {d.firstName} {d.lastName}
                                </Option>
                            ))}
                        </Select>
                    )}
                </div>
                <div>
                    {studios.length > 0 && (
                        <Select
                            showSearch
                            placeholder="Search Studios"
                            defaultActiveFirstOption={false}
                            showArrow={false}
                            style={{ width: "20vw" }}
                            filterOption={false}
                            defaultValue={[`${studios[0]?._id}`]}
                            onSearch={handleStudioChange}
                            notFoundContent={null}
                        >
                            {studiosList.map((d) => (
                                <Option key={d._id} onClick={(d) => view === 'month' ? handleChangeStudioMonth(d?.key) : handleChangeStudio(d?.key)}>
                                    {d.name}
                                </Option>
                            ))}
                        </Select>
                    )}
                </div>
                <div>
                    <Alert message={message} type="success" />
                </div>
            </div>
            <DnDCalender
                className={style.calendarContainer}
                selectable={true}
                localizer={localizer}
                // defaultView={Views.WEEK}
                events={events && events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
                resizable={true}
                onView={(view) => {
                    // Handle different data based on the view
                    if (view === Views.MONTH) {
                        setView("month");
                        // fetchDataMonth()
                    } else {
                        setView("week");
                        // Fetch or set data for week or day view
                        // fetchData()
                    }
                }}
                min={
                    new Date(today.getFullYear(), today.getMonth(), today.getDate(), 9)
                }
            />
        </div>
    );
};

const WrappedForm = Form.create({ name: "horizontal_login" })(Slot);

Slot.propTypes = {
    users: PropTypes.array,
    usersFetchRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    users: state.cabinet.users.data,
});

export const SlotContainer = withRouter(
    connect(mapStateToProps, { usersFetchRequest })(WrappedForm)
);
