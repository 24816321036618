import { Button, Form, Icon, Input } from "antd";
import React, { useState } from "react";

import { Link } from "react-router-dom";
import style from "./index.module.scss";

const SignupFormComponent = (props) => {
  const { getFieldDecorator } = props.form;
  const [visible, setVisible] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        props.onSubmit(values);
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit} className={style.signupForm}>
      <h1 className={style.authHeader}>Create account</h1>
      <Form.Item>
        {getFieldDecorator("firstName", {
          rules: [
            { required: true, message: "Please input your First Name!" },
            { min: 2, message: "Min length name 2 symbols!" },
            { max: 30, message: "Max length name 30 symbols!" },
          ],
        })(
          <Input
            // prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="First Name"
            style={{ borderRadius: "4px", height: "40px" }}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator("lastName", {
          rules: [
            { required: true, message: "Please input your Last Name!" },
            { min: 2, message: "Min length name 2 symbols!" },
            { max: 30, message: "Max length name 30 symbols!" },
          ],
        })(
          <Input
            // prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Last Name"
            style={{ borderRadius: "4px", height: "40px" }}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator("company", {
          rules: [
            { required: true, message: "Please input your Company Name!" },
            { min: 2, message: "Min length name 2 symbols!" },
            { max: 30, message: "Max length name 30 symbols!" },
          ],
        })(
          <Input
            // prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Company"
            style={{ borderRadius: "4px", height: "40px" }}
          />
        )}
      </Form.Item>
      <Form.Item
        {...(props.isError && {
          help: props.errorMessage,
          validateStatus: "error",
        })}
      >
        {getFieldDecorator("email", {
          rules: [
            { required: true, message: "Please input your email!" },
            { type: "email", message: "The input is not valid E-mail!" },
          ],
        })(
          <Input
            // prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
            style={{ borderRadius: "4px", height: "40px" }}
          />
        )}
      </Form.Item>
      <Form.Item>
        {getFieldDecorator("password", {
          rules: [
            { required: true, message: "Please input your password!" },
            { min: 6, message: "Min length password 6 symbols!" },
            { max: 30, message: "Max length password 30 symbols!" },
          ],
        })(
          <Input
            type={visible ? "text" : "password"}
            placeholder="Password"
            style={{ borderRadius: "4px", height: "40px" }}
            suffix={
              <Icon
                type={visible ? "eye-invisible" : "eye"}
                onClick={() => setVisible(!visible)}
                style={{ cursor: "pointer" }}
              />
            }
          />
        )}
      </Form.Item>
      <Form.Item>
        <Button
          loading={props.isLoading}
          type="primary"
          style={{
            width: "100%",
            borderRadius: "4px",
            height: "40px",
            background: "#333333",
            border: "none",
          }}
          htmlType="submit"
        >
          Sign up
        </Button>
        <div className={style.singupLinks}>
          Already have an account?
          <Link to="/signin" style={{ color: "#07B464" }}>{` Signin `}</Link>
          {/* or <Link to="/restore-password">Forgot password</Link> */}
        </div>
      </Form.Item>
    </Form>
  );
};

export const SignupForm = Form.create({ name: "signinForm" })(
  SignupFormComponent
);
